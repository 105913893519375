// store.ts
import {
    createStore,
    Store,
} from 'vuex';

import { app } from './app';
import { user } from '@/store/user'
import {currentHzba} from './currentHzba';
import localforage from 'localforage';
import VuexORMLocalForage from "vuex-orm-localforage";
import VuexORMisDirtyPlugin from '@vuex-orm/plugin-change-flags';

import VuexORM from '@vuex-orm/core';
import VuexORMAxios from '@vuex-orm/plugin-axios';
import {vuexORMAxiosSettings} from "@/api/api";

//** GISolutions models general */
import User from '@/models/user';
import Immobilie, { immobilie } from '@/models/immobilie.model';
import EnumEingabewerte from '@/models/enum-eingabewerte.model';
import LocalMetaData from "@/models/local-meta-data";
import Faq from '@/models/faq.model';
// import Bibliothek from '@models/bibliothek.model';
import {InjectionKey} from "vue";
import {IState} from "@/store/store.types";
import BestandsaufnahmeModel, { bestandsaufnahme } from '@/models/ba/models/bestandsaufnahme.model';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { fragenStore } from './fragen-store';

VuexORM.use(VuexORMAxios, vuexORMAxiosSettings());

const database = new VuexORM.Database();

database.register(User);
database.register(Immobilie, immobilie);
database.register(BestandsaufnahmeModel, bestandsaufnahme);
database.register(EnumEingabewerte);
database.register(LocalMetaData);
database.register(Faq);
// database.register(Bibliothek);


VuexORM.use(VuexORMLocalForage, {
    database,
    localforage: {
        driver: [localforage.INDEXEDDB], // will be replaced with CordovaSQLiteDriver if available
        name: 'aconApp',
        version: 1.0,
        size: 4980736, // Size of database, in bytes. WebSQL-only for now.
        storeName: 'hzba', // Should be alphanumeric, with underscores.
        description: 'offline storage for hzba',
    },
    actions: {
        $get: '$getFromLocal',
        $fetch: '$fetchFromLocal',
        $create: '$createLocally',
        $update: '$updateLocally',
        $replace: '$replaceLocally',
        $delete: '$deleteFromLocal',
        $deleteAll: '$deleteAllFromLocal',
    },
});

VuexORM.use(VuexORMisDirtyPlugin);

// defineDriver needs to be called async but VuexORMLocalForage does not allow this declaratively
// doing it manually instead
export const waitForSQLDriver = Promise.all(database.entities.map((entity) => {
    if(!entity.name) {
        return Promise.reject('VuexORM entity has no name');
    }
    // @ts-ignore no $localStore on model
    if(!entity.model.$localStore) {
        return Promise.reject(`VuexORM entity ${entity.name} has no local store`);
    }
    // @ts-ignore
    return entity.model.$localStore?.defineDriver(CordovaSQLiteDriver).then(() => {
        // @ts-ignore
        entity.model.$localStore?.setDriver([CordovaSQLiteDriver._driver, localforage.INDEXEDDB]);
    });
}));

// define injection key
export const storeTypes: InjectionKey<Store<IState>> = Symbol()


//TODO @juergenhah: change process.env.Node... to our environment variable
export const store = createStore<IState>({
    plugins:
        process.env.NODE_ENV !== 'production'
            ? [VuexORM.install(database)]
            : [VuexORM.install(database)],
    modules: {
        app,
        user,
        currentHzba,
        fragenStore
    }
});

