import Immobilie from "@/models/immobilie.model";
import { Response } from "@vuex-orm/plugin-axios"

export const deleteDocumentFromObject = async (objectId: number, documentId: number) => {
    let response: Response;
    try {
        response = await Immobilie.api().put(`/immobilies/deleteDocument/${objectId}`, { documentId }, { save: false });
    } catch (error: any) {
        throw new Error(error?.response?.data?.error ?? "Server error while deleting document from object");
    }
    return response.response.data;
};