<template>
  <div class="handle">
    <div
      class="close-button w-5 h-5 cursor-pointer"
      @click="$emit('close')"
    >
      <ion-icon
        class="black-icon close-icon"
        :icon="close"
      />
    </div>
  </div>
  <div
    class="h-full"
    @touchmove.passive="handleMove"
    @pointermove.passive="handleMove"
    @mousemove.passive="handleMove"
  >
    <slot />
  </div>
</template>

<script
    setup
    lang="ts"
>
import { IonIcon, IonModal } from "@ionic/vue";
import { close } from "ionicons/icons";

const props = defineProps<{
    modalElement: InstanceType<typeof IonModal> | undefined;
    disableScroll?: boolean;
}>();

defineEmits(['close']);

const handleMove = (event: Event) => {
    if (props.disableScroll) {
        event.stopPropagation();
    }
};

</script>


<style
    scoped
    lang="scss"
>
.handle {
    width: 100%;
    height: 1rem;
    background: var(--ion-background-color);
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    cursor: ns-resize;
}

.close-button {
    position: absolute;
    top: -2px;
    right: 1.5rem;
    border-radius: 50%;

    &:hover {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }

    ion-icon {
        color: var(--white100);

        &.black-icon {
            color: var(--black100);
        }

        &.close-icon {
            font-size: 20px;
        }
    }
}
</style>