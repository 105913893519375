import { FailHandlers } from "@/types/app/monitoring-types";
import { Monitoring } from "@/utilities/monitoring";
import { AxiosRequestConfig } from "axios";
import { createCancelController, exponentialBackoff, ExponentialBackoffOptions } from "./helper/api-helpers";
import { AsyncQueue } from "./helper/async-queue";

/*

Goal of this module is to manage single API calls with the following options for now:
- Retry with exponential backoff
- Queues
- Timeouts
- Adding callback for delayed responses
- Graceful interruption (cancellation tokens)
- Pre request checks, e.g. network connection
- Error handling and monitoring

The module is not:
- Handling the temporal dependencies between differnt API calls, use stores for that

Further ideas:
- Context utilities for monitoring
- Migrate to AbortController when available
- queue alternative: start calls simultaneously, but only the response of the last one started is returned
    
*/

interface DelayedOptions {
    delay?: number;
    onDelayed: () => void;
}

interface PreRequestCheck {
    check: () => boolean;
    onAbort?: () => void;
    fallbackValue: any;
}

interface Timeout {
    time: number;
    onTimeout?: () => void;
    message: string;
}

interface ManageApiCallOptions<T = any> {
    onStart?: () => void;
    cancelMessage?: string;
    timeout?: Timeout;
    errorMessage?: string;
    failHandlers?: FailHandlers<T>;
    queue?: AsyncQueue;
    retryOptions?: ExponentialBackoffOptions;
    delayedOptions?: DelayedOptions;
    preRequestCheck?: PreRequestCheck;
}

type ApiCallFunctionSignature<T, K> = (data: T, config?: AxiosRequestConfig) => Promise<K>;

const getDefaultConfigs = (manageOptions?: ManageApiCallOptions) => {
    const options = manageOptions ?? {};
    return {
        cancelMessage: options.cancelMessage ?? "ApiCallManager: Request was cancelled",
        timeout: options.timeout ?? {
            time: 35000,
            message: "ApiCallManager: Request timed out",
        },
        errorMessage: options.errorMessage ?? "ApiCallManager: Request failed",
        failHandlers: options.failHandlers ?? {},
        onStart: options.onStart ?? null,
        queue: options.queue,
        retryOptions: options.retryOptions,
        delayedOptions: options.delayedOptions ?
            (options.delayedOptions.delay ? options.delayedOptions : { delay: 5000, onDelayed: options.delayedOptions.onDelayed })
            : null,
        preRequestCheck: options.preRequestCheck
    };
};

export const manageApiCall = <T, K>(call: ApiCallFunctionSignature<T, K>, options?: ManageApiCallOptions) => {

    const manager = getDefaultConfigs(options);
    const additionalRequestConfig: AxiosRequestConfig = {};

    const cancelController = createCancelController(additionalRequestConfig);
    cancelController.create();

    let makeRequest;
    const cancelRequest = (overrideMessage?: string) => cancelController.cancel(overrideMessage ?? manager.cancelMessage);

    const maybeStartTimeout = <T>(resultPromise: Promise<T>) => {
        if (manager.timeout) {
            const timeoutId = setTimeout(() => {
                cancelRequest(manager.timeout.message);
                manager.timeout.onTimeout?.();
            }, manager.timeout.time);

            resultPromise.finally(() => clearTimeout(timeoutId));
        }
    };

    const maybeWatchForDelayed = <T>(resultPromise: Promise<T>) => {
        if (manager.delayedOptions) {
            const { delay, onDelayed } = manager.delayedOptions;
            const timeoutId = setTimeout(onDelayed, delay);
            resultPromise.finally(() => clearTimeout(timeoutId));
        }
    };

    const maybeDontStartRequest = () => {
        if (manager.preRequestCheck && !manager.preRequestCheck.check()) {
            manager.preRequestCheck.onAbort?.();
            return true;
        }
        return false;
    };

    if (manager.queue) {
        makeRequest = (data: T, config?: AxiosRequestConfig) => {
            const monitoredCall = Monitoring.decorateWithErrorHandling(call, manager.errorMessage, manager.failHandlers);

            if (maybeDontStartRequest()) return Promise.resolve(manager.preRequestCheck?.fallbackValue);
            manager?.onStart?.();

            // @ts-ignore - compiler doesn't know that queue is defined
            const promise = manager.queue.enqueue(() => monitoredCall(data, { ...config, ...additionalRequestConfig }), manager.retryOptions);
            maybeStartTimeout(promise);
            maybeWatchForDelayed(promise);
            return promise;
        };
    } else if (manager.retryOptions) {
        makeRequest = (data: T, config?: AxiosRequestConfig) => {
            const executeCall = () => call(data, { ...config, ...additionalRequestConfig });
            const retryCall = () => exponentialBackoff(executeCall, manager.retryOptions);
            const monitoredCall = Monitoring.decorateWithErrorHandling(retryCall, manager.errorMessage, manager.failHandlers);

            if (maybeDontStartRequest()) return Promise.resolve(manager.preRequestCheck?.fallbackValue);
            manager?.onStart?.();

            const promise = monitoredCall();
            maybeStartTimeout(promise);
            maybeWatchForDelayed(promise);
            return promise;
        };
    } else {
        makeRequest = (data: T, config?: AxiosRequestConfig) => {
            const monitoredCall = Monitoring.decorateWithErrorHandling(call, manager.errorMessage, manager.failHandlers);

            if (maybeDontStartRequest()) return Promise.resolve(manager.preRequestCheck?.fallbackValue);
            manager?.onStart?.();

            const promise = monitoredCall(data, { ...config, ...additionalRequestConfig });
            maybeStartTimeout(promise);
            maybeWatchForDelayed(promise);
            return promise;
        };
    }

    return {
        makeRequest,
        cancelRequest
    };
};