import { useBauflaechen } from '@/components/Pdf/Bericht_Bauflaechen/useBauflaechen';
import { useSeilzugflaechen } from '@/components/Pdf/Bericht_Seilzugflaechen/useSeilzugflaechen';
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import { BestandsaufnahmeJson } from '@/models/ba/interfaces/IBestandsaufnahme';
import BestandsaufnahmeModel from '@/models/ba/models/bestandsaufnahme.model';
import Immobilie from '@/models/immobilie.model';
import { ICurrentHzba } from '@/store/store.types';
import { Monitoring } from '@/utilities/monitoring';
import {
    loadHzbaPhotosLocal,
    saveHzbaPhotosLocal
} from '@/utilities/photo-storage';
import { Network } from '@capacitor/network';
import { Module } from 'vuex';

const { summarizeOverall } = useBauflaechen();
const { summarizeOverallSeilzugflaechen } = useSeilzugflaechen();

export const currentHzba: Module<ICurrentHzba, any> = {
  namespaced: true,
  state: () => ({
    currentBa: undefined,
    photoUploads: undefined,
    uploadStatus: undefined,
    pleasePauseSync: false
  }),
  mutations: {
    setCurrentBa(state: ICurrentHzba, payload: Bestandsaufnahme) {
      state.currentBa = payload;
    },
    setUploadStatus(state: ICurrentHzba, payload) {
      state.uploadStatus = payload;
    },
    setPhotoUploadTasks(state: ICurrentHzba, payload) {
      state.photoUploads = payload;
    },
    setPleasePauseSync(state: ICurrentHzba, payload) {
      state.pleasePauseSync = payload;
    }
  },
  actions: {

    async updateCurrentBa({ commit, state }: any, surveyJson: BestandsaufnahmeJson) {
      if(!!surveyJson.id && surveyJson.id === state.currentBa?.id) {
        surveyJson.isLocal = false;
        const ba = new Bestandsaufnahme(surveyJson);
        commit('currentHzba/setCurrentBa', ba, { root: true });
      }
    },

    async setCurrentHzba({ state }: { state: ICurrentHzba }, ba: BestandsaufnahmeModel ) {
      if (!ba) {
        console.warn('[currentHzba/setCurrentHzba] setting ba failed because param ba is null or undefined', ba, !ba);
        state.currentBa = undefined;
        return;
      }

      const currentBaJson = ba.copyJson();
      const currentBa = new Bestandsaufnahme(currentBaJson, undefined);

      if ( !currentBa.areMinimumFragenblocksSet) {
        const promises: Promise<any>[] = [];

        currentBa.fragenblocks?.forEach((el) => {
            promises.push(el.setupFragenblockMinimumInstances());
        });

        await Promise.all(promises);
      }

      state.currentBa = currentBa;
      await loadHzbaPhotosLocal(state.currentBa);

      await this.dispatch("currentHzba/setValueDetectors");
    },

    async setValueDetectors({ state, dispatch }: { state: any; dispatch: any }) {
        state.currentBa.fragenblocks?.forEach((el: any) => {
          el.setupDetectors();
        });
      },

    async saveHzbaLocally({ state, dispatch }: { state: any; dispatch: any }) {
      try {
        if (!state.currentBa.isLocal) {
        const im = Immobilie.find(state.currentBa.immobilie);
          if (im) {
            im!.isDownloaded = true;
            await Immobilie.dispatch('storeMoverObject', im );
          }
        }

        const networkStatus = await Network.getStatus();

        if ( !networkStatus.connected  ) {
          state.currentBa.hasUnsavedChanges = true;
        }

        let malusResult;
        switch (state.currentBa.identifier) {
          case 'rippistein-bauflaechen':
            malusResult = summarizeOverall( state.currentBa)
            state.currentBa.malus = malusResult.amount
            state.currentBa.malusColor = malusResult.color
            break;
          case 'rippistein-seilzugflaechen':
            malusResult = summarizeOverallSeilzugflaechen( state.currentBa)
            state.currentBa.malus = malusResult.amount
            state.currentBa.malusColor = malusResult.color
            break;
          default:
            //vorlagen identifier not considered yet
            state.currentBa.malus = null;
            state.currentBa.malusColor = null;
            break;
        }
        state.currentBa.isDownloaded = true;

        console.log("SAVE HZBA LOCALLY")

        const ba = state.currentBa;
        await saveHzbaPhotosLocal(state.currentBa);
        await BestandsaufnahmeModel.dispatch('storeMoverSurvey', await ba.toClassJson() );

        // await BestandsaufnahmeModel.dispatch('addToPersistedBestandsaufnahmes', uHzba);
      } catch (err: any) {
        Monitoring.chainError("Error trying to save hzba locally: ", err);
      }
    },

    async confirmSuccessfullUpload({state, dispatch,}: { state: any; dispatch: any; }) {
      state.photoUploads = undefined;
    },
  },
  getters: {
    /**
     * Evaluates if user is allowed to edit hzbas
     * Roles allowed to edit hzbas are projektverwalter (1) and gutachter (3).
     * gutachter (3) are only allowed to edit assigned hzbas but because they cannot fetch not-assigned hzbas, we don't need to evaluate this here
     * Immobilienverwalter (2) are never allowed to edit hzbas, even if assigned to hzba
     *
     * @returns if current user is allowed to edit hzbas
     */
    hasHzbaEditPermission( state: any) {
      const { user } = useUser();

      // roles allowed to edit hzbas are projektverwalter (1) and gutachter (2), and bearbeiter (6)
      return [1, 3, 6].includes(user.value?.role.id ?? -1);
    },

    isBaReadonly(state: any, getters: any, rootState: any, rootGetters: any) {
      return state.currentBa?.status === "FREIGEGEBEN" ||
        state.currentBa?.status === "ABGESCHLOSSEN" ||
        !getters.hasHzbaEditPermission
    }
  },
};
