<template>
  <hzba-base-modal
    :title="fragenblock && fragenblock.getTitle()"
    :open="open"
    :ask-before-close="false"
    :go-back-mode="true"
    @close="() => cancel()"
  >
    <FragenblockContent
      :fragenblock-base="fragenblockBase"
      :modal-depth="modalDepth"
      :survey-map-modal="surveyMapModal"
      @delete="deleteInstance"
      @duplicate="duplicateInstance"
    />
  </hzba-base-modal>
</template>

<script lang="ts">
import HzbaBaseModal from "@/components/Base/BaseModal";
import SectionWrapper from "@/components/Base/SectionWrapper.vue";
import HzbaForm from "@/components/hzba/Base/HzbaForm.vue";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput";
import HzbaDeleteDuplicateItem from "@/components/hzba/Base/Input/HzbaDeleteDuplicateItem.vue";
import HzbaModalItemBlock from "@/components/hzba/Base/Input/HzbaModalItemBlock.vue";
import RecursiveForm from "@/components/hzba/RecursiveForm.vue";
import SurveyMapFragenblockButtons from '@/components/v2/Map/SurveyMapFragenblockButtons.vue';
import {
  useIdentifierFragenblockFinder
} from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import { Fragenblock } from "@/models/ba/Fragenblock";
import { store } from "@/store/store";
import { openHzbaModal } from "@/utilities/modal-helper";
import { IonContent, modalController } from "@ionic/vue";
import { computed, defineComponent, PropType } from "vue";
import FragenblockContent from "./FragenblockContent.vue";

export default defineComponent({
  name: "FragenblockModal",
  components: {
    RecursiveForm,
    HzbaDeleteDuplicateItem,
    SurveyMapFragenblockButtons,
    HzbaModalItemBlock,
    HzbaForm,
    HzbaBaseInput, HzbaGroup, HzbaBaseModal, IonContent, SectionWrapper,
    FragenblockContent
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    fragenblockBase: {
      type: Object as PropType<Fragenblock>,
      required: true,
    },
    modalDepth: {
      type: Number,
      default: 0,
    },
    surveyMapModal: {
      type: Boolean,
      required: false
    }
  },
  emits: ["close"],
  setup(props: any) {
    console.log("setup fragenblock modal");

    const currentBa = computed(() => {
      return store.state.currentHzba.currentBa;
    });

    const fragenblock = computed<Fragenblock | undefined>(() => {
      return currentBa.value && useIdentifierFragenblockFinder(currentBa.value, props.fragenblockBase.path, undefined);
    });

    async function cancel() {
      await modalController.dismiss();
    }

    async function deleteInstance() {
      const copiedFragenblock = await fragenblock.value?.toClassJson();

      await modalController.dismiss({
        action: 'DELETE',
        data: copiedFragenblock,
      });
    }

    async function duplicateInstance() {
      const copiedFragenblock = await fragenblock.value?.toClassJson();

      await modalController.dismiss({
        action: 'DUPLICATE',
        data: copiedFragenblock,
      });
    }



    return {
      fragenblock,
      currentBa,
      cancel,
      deleteInstance,
      duplicateInstance,
      openHzbaModal,
    };
  },
});
</script>