export const visibilityWatcher = (element: any, callback: any, onlyOnce = false) => {
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.intersectionRatio > 0) {
                callback();
                if (onlyOnce) {
                    observer.unobserve(element);
                }
            }
        });
    });

    observer.observe(element);
    return observer.unobserve;
};

export const watchElementDimensions = (
    element: Element | null | undefined,
    callback: (width: number, height: number) => void
): (() => void) | null => {
    if (!element) return null;

    // @ts-ignore ResizeObserver
    const resizeObserver = new ResizeObserver(() => callback(element.clientWidth, element.clientHeight));
    resizeObserver.observe(element);

    callback(element.clientWidth, element.clientHeight);

    return () => resizeObserver.unobserve(element);
};