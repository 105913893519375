import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FragenblockContent = _resolveComponent("FragenblockContent")!
  const _component_hzba_base_modal = _resolveComponent("hzba-base-modal")!

  return (_openBlock(), _createBlock(_component_hzba_base_modal, {
    title: _ctx.fragenblock && _ctx.fragenblock.getTitle(),
    open: _ctx.open,
    "ask-before-close": false,
    "go-back-mode": true,
    onClose: _cache[0] || (_cache[0] = () => _ctx.cancel())
  }, {
    default: _withCtx(() => [
      _createVNode(_component_FragenblockContent, {
        "fragenblock-base": _ctx.fragenblockBase,
        "modal-depth": _ctx.modalDepth,
        "survey-map-modal": _ctx.surveyMapModal,
        onDelete: _ctx.deleteInstance,
        onDuplicate: _ctx.duplicateInstance
      }, null, 8, ["fragenblock-base", "modal-depth", "survey-map-modal", "onDelete", "onDuplicate"])
    ]),
    _: 1
  }, 8, ["title", "open"]))
}