/**
 * Returns the numeric value of a CSS property from the document root.
 * If the property isn't defined or can't be parsed as a number, returns 0.
 *
 * @param property - The CSS property name.
 * @returns The numeric value of the property.
 */
export const getCssPropertyAsNumber = (property: string): number => {
  const value = getComputedStyle(document.documentElement)
    .getPropertyValue(property)
    .trim();
    
  const number = parseFloat(value);
  return Number.isNaN(number) ? 0 : number;
};
