<template>
  <ion-page>
    <toolbar
      :title="baSubpage === 'summary' ? 'Vorschau' : t(`${organisationPrefix}toolbar.mobile.surveyDetail`) + ` - ${immobilie ? immobilie.externeObjektNr : '...'}`"
    >
      <template #leading>
        <ion-buttons slot="start">
          <ion-button
            fill="clear"
            class="px-0 -ml-4"
            size="small"
            @click="goBackFromSummary"
          >
            <ion-icon
              slot="icon-only"
              :icon="arrowBack"
              size="large"
            />
          </ion-button>
          <!-- icon "no network connection" -->
          <ion-button
            v-if="networkStatus === ('none' || 'undefined') && baSubpage !== 'summary'"
            :key="networkStatus"
            data-cy="network-status"
          >
            <img src="/assets/img/svg/Cloud_Error.svg">
          </ion-button>
        </ion-buttons>
      </template>
      <template #trailing>
        <ion-buttons slot="primary">
          <!-- icon "no network connection" -->
          <ion-button
            v-if="networkStatus === ('none' || 'undefined') && baSubpage === 'summary'"
            :key="networkStatus"
            class="ml-white-text"
            data-cy="network-status"
          >
            <img src="/assets/img/svg/Cloud_Error.svg">
          </ion-button>

          <ion-button
            v-if="baSubpage !== 'summary'"
            class="ml-white-text"
            data-cy="button-preview"
            @click="showSummary"
          >
            {{ $t("hzba.buttons.vorschau") }}
          </ion-button>
        </ion-buttons>
      </template>
    </toolbar>

    <ion-content>
      <div v-if="ba">
        <div class="md:container lg:flex md:mx-auto">
          <div
            class="overview-item row lg:block items-center lg:w-1/3 lg-boxed-container lg:p-4 self-start z-50 top-0 lg:top-4"
          >
            <div class="mb-8 hidden lg:block">
              <h3 class="mt-6">
                <strong>{{ $t("hzba.uebersicht.objektTitel") }} {{ immobilie && immobilie.externeObjektNr
                }}</strong><br>{{
                  getLocalization(ba.name) }}
              </h3>

              <!-- Begehungsdatum -->
              <p class="flex my-0 items-center">
                <date-change
                  v-model="changedBegehungsdate"
                  format="text"
                  :title="$t(`${organisationPrefix}hzba.datum.begehung`)"
                  :loading="isPerformingDatabaseUpdate"
                  :readonly="!canUpdateBegehungsDate"
                  @changed="saveBegehungsDate"
                />
              </p>

              <!-- Sichtungsdatum -->
              <p
                v-if="featureFlags?.survey?.showSichtungsdatum"
                class="flex items-center"
                :class="[
                  canUpdateBegehungsDate ? 'my-2' : 'my-3'
                ]"
              >
                <date-change
                  v-model="changedSichtungsdate"
                  format="text"
                  :title="$t(`${organisationPrefix}hzba.datum.sichtung`)"
                  :loading="isPerformingDatabaseUpdate"
                  :readonly="!canUpdateSichtungsDate"
                  @changed="saveSichtungsDate"
                />
              </p>

              <!-- Last update -->
              <p
                v-if="ba.updatedAt"
                class="mt-3"
              >
                {{ $t("hzba.uebersicht.updateTitel") }}: {{ new Date(ba.updatedAt).toLocaleString("de-De") }}
              </p>
              <p v-if="ba.updatedBy?.username">
                {{ $t("hzba.uebersicht.updateVonTitel") }} {{ ba.updatedBy?.username }}
              </p>
              <p v-if="HzbaStatus[ba.status]">
                {{ $t("hzba.uebersicht.statusTitel") }}: {{ translatedHzbaStatus(ba.status, t) }} {{
                  ba.bearbeitenderNutzer?.username ? `(${ba.bearbeitenderNutzer.username})` : '' }}
              </p>
              <p>
                {{ `${t('createSurvey.person')}: ${ba?.gutachter ? ba.gutachter.username : t('hzba.notAssigned')}` }}
              </p>
              <div class="flex flex-wrap gap-2">
                <AButton
                  v-if="featureFlags?.survey?.changeStatus"
                  :btn-tertiary="true"
                  data-cy="hzba-status"
                  @click="openChangeStatusAlert(ba, t)"
                >
                  {{ t('hzba.updateStatus') }}
                </AButton>
                <AButton
                  v-if="featureFlags?.survey?.changePerson"
                  :btn-tertiary="true"
                  data-cy="hzba-person"
                  @click="openChangePersonAlert(ba)"
                >
                  {{ t('hzba.updatePerson') }}
                </AButton>
              </div>
              <p v-if="ba.isLocal">
                {{ $t("hzba.uebersicht.asynchron") }}
              </p>
            </div>

            <BaTabs
              :selected-view="baSubpage"
              :show-map-button="!!featureFlags?.surveyMap"
              @showSummary="showSummary"
              @changeTab="changeTab"
              @show-map="toggleFirstModal"
            />
          </div>

          <div class="lg:w-2/3">
            <div class="pb-8 container">
              <socket-disconnected-hint class="mt-4 mr-4 ml-4">
                {{ t(`${organisationPrefix}hzba.noSocketConnection`) }}
              </socket-disconnected-hint>

              <HzbaMainView
                v-if="ba && baSubpage !== 'summary'"
                :ba="ba"
                :immobilie="immobilie"
                :route-name="baSubpage"
              />
              <HzbaSummary v-else-if="baSubpage === 'summary'" />
            </div>
          </div>
        </div>
      </div>
      <Skeleton v-else />
      <AppFloatingIcon
        v-if="isMobileScreen && featureFlags?.surveyMap && ba"
        class="floating-button"
        :is-visible="!isFirstModalOpen"
      >
        <AButton
          type="button"
          class="h-12 w-12"
          fill="solid"
          shape="round"
          data-cy="btn-map-mobile"
          :btn-primary="true"
          @click.stop="openFirstModal"
        >
          <ion-icon
            :icon="mapOutline"
            class="w-6 h-6 map-icon"
          />
        </AButton>
      </AppFloatingIcon>
    </ion-content>
    <ion-modal
      ref="firstModalTemplateRef"
      class="modal-style show-as-background-modal"
      :is-open="isFirstModalOpen"
      :backdrop-dismiss="false"
      :can-dismiss="true"
      :initial-breakpoint="initialBreakpointFirstModal"
      :breakpoints="breakpoints"
      :backdrop-breakpoint="1.0"
      :keep-contents-mounted="true"
      @ion-breakpoint-did-change="onFirstModalBreakpointChange"
    >
      <IonModalNoScrollContent
        :modal-element="firstModalTemplateRef"
        :disable-scroll="disableDragEvents"
        @close="hideFirstModal"
      >
        <AppMap
          v-if="featureFlags?.surveyMap"
          style="height: 100%"
          :identifier="'surveyMap'"
          :ba="ba"
          :map-settings="currentProject.mapConfig?.surveyMap"
          :is-record-geometry-enabled="featureFlags?.recordGeometry"
          :property-location="immobilie ? { lat: immobilie.geolocationLat, lon: immobilie.geolocationLon } : undefined"
          :update-features-counter="featureChangeCounter"
          :geo-data-list-active="isSecondModalOpen"
          @list:open="openGeodataList"
          @list:close="closeGeoDataListModal"
          @list:open-item="openFragenblockModal"
          @drawing:created="openFragenblockModal"
          @drag:start="disableDragEvents = true"
          @drag:end="disableDragEvents = false"
        />
      </IonModalNoScrollContent>
    </ion-modal>
    <ion-modal
      ref="secondModalTemplateRef"
      class="modal-style show-as-foreground-modal"
      :is-open="isSecondModalOpen"
      :backdrop-dismiss="false"
      :can-dismiss="true"
      :initial-breakpoint="initialBreakpointSecondModal"
      :breakpoints="breakpoints"
      :backdrop-breakpoint="1.0"
      :keep-contents-mounted="true"
      @ion-breakpoint-did-change="onSecondModalBreakpointChange"
    >
      <div class="geodata-list-handle" />
      <GeoDataListModal
        :data="mapFeatures"
        :preselected-item="selectedFragenblock"
        @dismiss="closeGeoDataListModal"
        @delete="deleteFragenblock"
      />
    </ion-modal>
    <SafeArea position="bottom" />
  </ion-page>
</template>

<script
  setup
  lang="ts"
>

import AButton from "@/components/Base/AButton";
import DateChange from "@/components/Base/DateChange.vue";
import IonModalNoScrollContent from "@/components/Base/IonModalNoScrollContent.vue";
import SafeArea from "@/components/Base/SafeArea.vue";
import Toolbar from "@/components/Navigation/Toolbar";
import SocketDisconnectedHint from "@/components/Other/SocketDisconnectedHint.vue";
import Skeleton from "@/components/Skeleton.vue";
import BaTabs from "@/components/hzba/BaTabs.vue";
import HzbaSummary from "@/components/hzba/Views/BaSummary.vue";
import HzbaMainView from "@/components/hzba/Views/HzbaMainView";
import AppFloatingIcon from "@/components/v2/App/AppFloatingIcon.vue";
import AppMap from '@/components/v2/App/AppMap.vue';
import GeoDataListModal from "@/components/v2/Map/GeoDataListModal.vue";
import useBestandsaufnahmeUpload from "@/composables/Bestandsaufnahme/useBestandsaufnahmeUpload";
import useDateChangeUtils from "@/composables/Bestandsaufnahme/useDateChangeUtils";
import useOnceBestandsaufnahmeSubpage from "@/composables/Bestandsaufnahme/useOnceBestandsaufnahmeSubpage";
import usePersonChangeUtils from "@/composables/Bestandsaufnahme/usePersonChangeUtils";
import useDoubleModalView from "@/composables/Navigation/useDoubleModalView";
import useToasts from "@/composables/useToasts";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import { Fragenblock } from "@/models/ba/Fragenblock";
import { HzbaStatus, translatedHzbaStatus } from "@/models/ba/interfaces/IBestandsaufnahme";
import User from "@/models/user";
import getLocalization from "@/utilities/get-localization";
import { Monitoring } from "@/utilities/monitoring";
import { Network } from "@capacitor/network";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonModal,
  IonPage,
  onIonViewWillEnter,
  onIonViewWillLeave
} from "@ionic/vue";
import { arrowBack, mapOutline } from "ionicons/icons";
import { ComputedRef, computed, onUnmounted, ref, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";

const organisationPrefix: ComputedRef<string> = computed(() => {
  const currentUser: User | null = User.query().first();

  return currentUser ? currentUser.organisationPrefix : "";
});

const { t } = useI18n({ useScope: 'global' });
const store = useStore();
const { user, canUpdateBegehungsDate, canUpdateSichtungsDate } = useUser();
const router = useRouter();
const lastRoute = ref();

const disableDragEvents = ref(false);

const {
  firstModalTemplateRef,
  secondModalTemplateRef,
  isFirstModalOpen,
  isSecondModalOpen,
  openFirstModal,
  hideFirstModal,
  toggleFirstModal,
  openSecondModal,
  hideSecondModal,
  breakpoints,
  initialBreakpointFirstModal,
  initialBreakpointSecondModal,
  onFirstModalBreakpointChange,
  onSecondModalBreakpointChange
} = useDoubleModalView({ startingBreakpointFirstModal: 0.7, startingBreakpointSecondModalRelative: 0.4 });

const toast = useToasts();

const isMobileScreen = computed(() => {
  return store.getters["app/isMobile"];
});
const networkStatus = ref();
const featureFlags = computed(() => user.value?.organisation?.featureFlags);
const isSocketConnected = computed(() => {
  return store.getters["app/isSocketConnected"];
});
const currentProject = computed(() => store.state.user.currentUserProject);

const { ba, immobilie, baSubpageName: baSubpage, localSubpage, openChangeStatusAlert } = useOnceBestandsaufnahmeSubpage({ onIonViewWillEnter, onIonViewWillLeave });
const { updateSurvey } = useBestandsaufnahmeUpload();
const {
  isPerformingDatabaseUpdate,
  changedBegehungsdate,
  changedSichtungsdate,
  saveBegehungsDate,
  saveSichtungsDate
} = useDateChangeUtils(ba);
const { openChangePersonAlert } = usePersonChangeUtils();

const featureChangeCounter = ref(0);
const selectedFragenblock = ref<Fragenblock | undefined>();
const parentFragenblock = computed(() => {
  return ba.value?.findSectionByMapGeometryConfig() ?? {} as Fragenblock;
});
const mapFeatures = computed(() => {
  return parentFragenblock.value?.fragenblocks?.filter(item => item.geoJson) ?? [];
});

const showSummary = () => {
  lastRoute.value = baSubpage.value;
  // router.replace(`/ba/${ba.value!.id}/summary`)
  history.replaceState({}, 'title', `/ba/${ba.value!.id}/summary`);
  localSubpage.value = 'summary';
};

const goBackFromSummary = () => {

  const rec = store.state.app.recentRoute;

  // console.log("goBackFromSummary", rec, baSubpage);
  if (baSubpage.value === "summary") {
    if (lastRoute.value) {
      changeTab(lastRoute.value);
      // router.replace(`/ba/${ba.value!.id}/${lastRoute.value}`)
    } else {
      changeTab('');
      // router.replace(`/ba/${ba.value!.id}`)
    }
  } else if (rec.name === 'bas') {
    router.replace("/bas");
  } else if (rec.name === "propertiesMap") {
    router.replace("/properties/map");
  }
  else {
    router.replace("/bas");
  }
  // router.replace(`/ba/${ba.value!.id}/${lastRoute.value}`)
};

const changeTab = (val?: string) => {
  if (val) {
    history.replaceState({}, 'title', `/ba/${ba.value!.id}/${val}`);
  } else {
    history.replaceState({}, 'title', `/ba/${ba.value!.id}`);
  }

  localSubpage.value = val;
};

const openGeodataList = () => {
  selectedFragenblock.value = undefined;
  openSecondModal();
};

const closeGeoDataListModal = () => {
  selectedFragenblock.value = undefined;
  hideSecondModal();
};

const openFragenblockModal = (fragenblock: Fragenblock) => {
  selectedFragenblock.value = fragenblock;
  openSecondModal();
};

const deleteFragenblock = async (fragenblock: Fragenblock) => {
  if (fragenblock) {
    store.dispatch("fragenStore/disconnectAndDeleteFragenblock", { ba: ba.value, fragenblock, parentFragenblock: parentFragenblock.value });
    featureChangeCounter.value += 1;
    selectedFragenblock.value = undefined;
  }
};

const networkListener = Network.addListener("networkStatusChange", (status) => {
  console.log('network status: ', status, 'connection type: ', status.connectionType);
  networkStatus.value = status.connectionType;
});

watch(isSocketConnected, (newVal, oldVal) => {
  if (newVal && oldVal === false) {
    // oldVal === null in initialization
    toast.socketConnected();
  }
});


onUnmounted(() => {
  networkListener?.remove();
});


</script>


<style
  scoped
  lang="scss"
>
.overview-item {
  position: sticky;
  align-self: flex-start !important;
  position: -webkit-sticky;
  /* for Safari */
}

.geodata-list-handle {
  width: 100%;
  z-index: 500;
  background: var(--ion-background-color);
  position: absolute;
  top: 0rem;
  height: 1.5rem;
  transform: translate(0%, -25%);
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.map-button {
  --background: var(--secondary);
}

.map-icon {
  color: var(--primary);
  min-width: 1.5rem;
}

.floating-button {
  z-index: 1000;
}

ion-icon {
  color: var(--white100);
}

ion-modal.modal-style {
  --width: 100vw;
  --safe-area: calc(var(--ion-safe-area-top) + var(--ion-safe-area-bottom));
  --height: calc(95vh - var(--safe-area, 0px) - var(--top-toolbar-height) - var(--bottom-toolbar-height, 5rem)) !important;
  bottom: calc(var(--bottom-toolbar-height) + var(--ion-safe-area-bottom, 0));
  top: calc(var(--top-toolbar-height) + var(--ion-safe-area-top, 0));
  --overflow: visible;
  --border-radius: 3rem;
  --box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);
}

// increase handle size for better drag/touch usability, hide UI handle because it expands with padding
ion-modal::part(handle) {
  background: transparent;
  height: 1rem;
  top: 0px;
}

// add custom but same-looking UI handle
ion-modal::part(handle)::after {
  content: "";
  width: 36px;
  height: 5px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  border-radius: 8px;
  z-index: 11;
  background-color: var(--ion-color-step-350, #c0c0be);
}

</style>