import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-590b5fa4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_2 = ["data-cy", "onClick"]
const _hoisted_3 = { class: "mt-8 lg:block hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressIndicator = _resolveComponent("ProgressIndicator")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_AButton = _resolveComponent("AButton")!
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (!$setup.isMinLarge)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($props.selectedView !== 'summary')
          ? (_openBlock(), _createBlock(_component_ion_segment, {
              key: 0,
              value: $props.selectedView,
              scrollable: "true",
              mode: "md",
              class: "w-screen lg:w-auto",
              onIonChange: _cache[0] || (_cache[0] = e => $setup.changeRoute(e))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tabs, (tab, index) => {
                  return (_openBlock(), _createBlock(_component_ion_segment_button, {
                    key: index,
                    value: tab.identifier,
                    class: _normalizeClass([
          $props.selectedView === tab.identifier || (!$props.selectedView && index === 0 ) ? 'active' : '',
          tab.isUnlocked() ? '' : 'disabled'
        ]),
                    layout: "icon-start",
                    "data-cy": `btn-mobile-nav-${tab.identifier}`
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ProgressIndicator, {
                        progress: tab.getProgress()
                      }, null, 8, ["progress"]),
                      _createVNode(_component_ion_label, { class: "normal-case" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(tab.getTitle()), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["value", "class", "data-cy"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "w-screen lg:w-auto mt-2",
        "on:ionChange": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('change', $event)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tabs, (tab, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["segment-button", [
        $props.selectedView === tab.identifier || (!$props.selectedView && index === 0 ) ? 'active' : '', tab.isUnlocked() ? '' : 'disabled'
      ]]),
            "data-cy": `btn-desktop-nav-${tab.identifier}`,
            layout: "icon-start",
            onClick: ($event: any) => ($setup.changeRouteDesktop(tab))
          }, [
            _createVNode(_component_ProgressIndicator, {
              progress: tab.getProgress(),
              style: {"margin-right":"8px"},
              "data-cy": "progress-desktop"
            }, null, 8, ["progress"]),
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(tab.getTitle()), 1)
              ]),
              _: 2
            }, 1024)
          ], 10, _hoisted_2))
        }), 128)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_AButton, {
            type: "button",
            fill: "solid",
            shape: "round",
            class: "mr-2",
            "data-cy": "btn-preview",
            "btn-primary": true,
            onClick: $setup.goToSummary
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("hzba.buttons.vorschau")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          ($setup.ba?.hasPdfSupport())
            ? (_openBlock(), _createBlock(_component_AButton, {
                key: 0,
                type: "button",
                fill: "solid",
                shape: "round",
                class: "mr-2",
                "data-cy": "btn-pdf-preview",
                "btn-primary": true,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.replace(`/ba/${$setup.ba?.id}/pdf-preview`)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("hzba.buttons.pdfVorschau")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($props.showMapButton)
            ? (_openBlock(), _createBlock(_component_AButton, {
                key: 1,
                type: "button",
                class: "map-button h-12 w-12",
                fill: "solid",
                shape: "round",
                "data-cy": "btn-map",
                "btn-primary": true,
                onClick: $setup.showMap
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: $setup.mapOutline,
                    class: "w-6 h-6"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ], 32))
}