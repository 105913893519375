<template>
  <ion-card
    v-if="ba && immobilie"
    class="hzbaCard m-0 rounded-sm bg-white acon-card-shadow"
    :class="[`${!isImmobilienverwalter() ? 'cursor-pointer' : ''}`,
             withAddButton ? 'flex flex-row pr-4' : 'px-4 flex flex-col justify-between']"
    @click="goToBa($event)"
  >
    <div
      v-if="withAddButton"
      class="h-auto pl-2 pr-2 mr-1 shadow-lg rounded-lg flex"
      @click.stop="$emit('create-survey')"
    >
      <ion-icon
        slot="icon-only"
        class="h-12 w-12 my-auto"
        :icon="addCircle"
      />
    </div>
    <div :class="{ 'ml-2' : withAddButton }">
      <div>
        <a-image
          v-if="uiMode === 'normal'"
          :image-class="'w-full h-44 object-cover md:block'"
          :src="immobilie && getPhotoUrl(immobilie.vorschaubild, { thumbnail: true })"
        />
        <div class="mt-4 flex-1">
          <div class="md:flex">
            <div class="flex-1 text-left">
              <div v-if="uiMode === 'normal'">
                <div>{{ ba.getName && ba.getName() }}</div>
                <h3
                  ion-text
                  class="mt-0 mb-0"
                  color="primary"
                >
                  {{ immobilie && immobilie.externeObjektNr }} ({{
                    moment(ba.begehungsdatum).format("YYYY")
                  }})
                </h3>

                <p class="mb-0 flex items-center">
                  <IonIcon
                    :icon="locationOutline"
                    class="mr-1"
                  />
                  {{ immobilie && ( immobilie.strasse ? immobilie.strasse + "," : "" ) }}
                  {{ immobilie && immobilie.plz }}
                  {{ immobilie && immobilie.stadt }}
                </p>
              </div>


              <div>
                <!-- Begehungsdatum -->
                <date-change
                  v-model="changedBegehungsdate"
                  format="icon"
                  :title="$t(`${organisationPrefix}hzba.datum.begehung`)"
                  :loading="isPerformingDatabaseUpdate"
                  :date-icon="calendarClearOutline"
                  :readonly="!canUpdateBegehungsDate"
                  @changed="saveBegehungsDate"
                />

                <!-- Sichtungsdatum -->
                <date-change
                  v-model="changedSichtungsdate"
                  format="icon"
                  :title="$t(`${organisationPrefix}hzba.datum.sichtung`)"
                  :loading="isPerformingDatabaseUpdate"
                  :date-icon="calendarOutline"
                  :readonly="!canUpdateSichtungsDate"
                  @changed="saveSichtungsDate"
                />

                <!-- Last update -->
                <p class="mt-2 mb-0 flex items-center">
                  <IonIcon
                    :icon="syncOutline"
                    class="mr-2"
                    :title="$t('hzba.zuletztAktualisiertAm')"
                  />
                  {{ moment(ba.updatedAt).format("DD.MM.YYYY") }}
                  <IonIcon
                    :icon="timeOutline"
                    class="ml-3 mr-1"
                  />
                  {{ moment(ba.updatedAt).format("HH:mm") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="uiMode === 'compact'"
        class="flex flex-row justify-between items-center"
      >
        <div class="text-lg my-4">
          <ion-button
            class="open-survey"
            @click.stop="goToBa($event)"
          >
            <ion-label
              v-if="!isMaxSmall"
              class="px-2"
            >
              {{ $t("immobilie.toOpen") }}
            </ion-label>
            <ion-icon
              v-else
              class="w-5 h-5"
              :icon="openOutline"
            />
          </ion-button>
        </div>
        <div
          class="flex flex-row items-center justify-end mini"
          :class="isMaxSmall ? 'gap-2' : 'gap-4'"
        >
          <div
            v-if="recommendedBericht(ba)"
            :btn-secondary="true"
            class="h-8 text-base cursor-pointer"
            shape="round"
            data-cy="button-berichtAnzeigen"
            @click="$event.stopPropagation(); openBericht($event, recommendedBericht(ba))"
          >
            <ion-icon
              size="large"
              :icon="documentTextOutline"
            />
          </div>
          <div
            v-else-if="ba?.hasPdfSupport()"
            class="cursor-pointer"
            type="button"
            fill="solid"
            shape="round"
            data-cy="btn-pdf-preview"
            :btn-primary="true"
            @click="$event.stopPropagation(); $router.push(`/ba/${ba?.id}/pdf-preview`)"
          >
            <ion-icon
              size="large"
              :icon="clipboardOutline"
            />
          </div>
          <div
            v-if="featureFlags?.survey?.changeStatus"
            class="flex cursor-pointer"
            :title="$t('immobilie.updateStatus')"
            @click="$event.stopPropagation(); handleStatusChange($event)"
          >
            <ion-icon
              size="large"
              :icon="statusIconState"
            />
          </div>
          <ProfileIcon
            v-if="featureFlags?.survey?.changePerson"
            :user="gutachter"
            @change="handlePersonChange"
          />
          <div
            v-if="alreadyInIndexDB"
            class="flex cursor-pointer"
            @mouseover="openToast($event)"
            @mouseout="closeToast()"
            @click.stop="closeToast(); removeFromIndexDB();"
          >
            <ion-icon
              size="large"
              :icon="arrowDownCircle"
            />
          </div>
          <div
            v-if="featureFlags.downloadSurvey && !alreadyInIndexDB"
            fill="clear"
            class="flex cursor-pointer"
            @click.stop="downloadBestandsaufnahme"
          >
            <ion-icon
              size="large"
              :icon="arrowDownCircleOutline"
            />
          </div>
          <div
            class="flex cursor-pointer"
            @click="$event.stopPropagation(), handleBaShare()"
          >
            <ion-icon
              size="large"
              :icon="shareSocialOutline"
            />
          </div>
        </div>
      </div>



      <!-- NORMAL (OLD) mode -->
      <div
        v-if="uiMode === 'normal'"
        class="md:justify-between m-4 mt-4"
      >
        <div class="flex items-end flex-wrap gap-y-4 gap-x-2">
          <PdfChip
            v-if="ba.malus"
            class="mr-2"
            :value="ba.malus"
            :color="malusColors[ba.malusColor]"
            :big="true"
          />

          <HzbaButton @click="handleStatusChange($event)">
            <span
              v-if="!HzbaStatus[ba.status]"
              class="mr-2"
            >
              Status unbekannt
            </span>
            <span
              v-else
              class="mr-2"
            >
              {{ translatedHzbaStatus(ba.status, t) }}
            </span>

            <ion-icon
              size="small"
              :icon="statusIconState"
            />
          </HzbaButton>
          <div
            v-if="alreadyInIndexDB"
            class="alreadyInIndexDB"
            @mouseover="openToast($event)"
            @mouseout="closeToast()"
            @click="$event.stopPropagation(); closeToast(); removeFromIndexDB()"
          >
            <ion-icon :icon="arrowDownCircle" />
          </div>
          <div
            v-if="featureFlags?.survey?.changePerson"
          >
            <ProfileIcon
              v-if="isMobile"
              :user="gutachter"
              @change="handlePersonChange"
            />
            <HzbaButton
              v-else
              @click="handlePersonChange"
            >
              <span>
                {{ gutachter ? gutachter.username : $t('hzba.notAssigned') }}
              </span>
            </HzbaButton>
          </div>
        </div>
        <div class="downloadPart my-4">
          <DownloadSurveyButton
            v-if="featureFlags.downloadSurvey && !alreadyInIndexDB"
            :is-downloading="isDownloading"
            @onClick="downloadBestandsaufnahme"
          />
        </div>

        <div class="text-left mt-2 sm:mb-0">
          <a-button
            v-if="recommendedBericht(ba)"
            :btn-secondary="true"
            class="w-full p-2 h-10 text-base"
            shape="round"
            data-cy="button-berichtAnzeigen"
            @click="openBericht($event, recommendedBericht(ba))"
          >
            {{ t("hzba.buttons.berichtAnzeigen") }}
          </a-button>

          <a-button
            v-if="relevantMaengellist && relevantMaengellist.length > 0"
            :btn-secondary="true"
            class="w-full mb-1 text-base"
            shape="round"
            data-cy="button-mangellisteAnzeigen"
            @click="openMaengelInImmobilie($event)"
          >
            {{ t("immobilie.buttons.mangellisteAnzeigen") }}
          </a-button>
        </div>
      </div>

      <div
        v-if="uiMode === 'normal'"
        class="flex cursor-pointer p-4 justify-end"
        @click="$event.stopPropagation(), handleBaShare()"
      >
        <IonIcon
          size="small"
          :icon="shareSocialOutline"
        />
      </div>
    </div>
  </ion-card>
</template>

<script lang="ts">
import AButton from "@/components/Base/AButton";
import AImage from "@/components/Base/AImage.vue";
import DateChange from "@/components/Base/DateChange.vue";
import PdfChip from "@/components/Pdf/Components/PdfChip.vue";
import DownloadSurveyButton from '@/components/v2/General/DownloadSurveyButton.vue';
import { useBestandsaufnahmens } from "@/composables/Bestandsaufnahme/useBestandsaufnahmens";
import useDateChangeUtils from "@/composables/Bestandsaufnahme/useDateChangeUtils";
import usePersonChangeUtils from "@/composables/Bestandsaufnahme/usePersonChangeUtils";
import { AppPlatform } from "@/composables/useAppInfo";
import useScreenSize from "@/composables/useScreenSize";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import {
    HzbaStatus,
    HzbaStatusCode,
    translatedHzbaStatus,
} from "@/models/ba/interfaces/IBestandsaufnahme";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import Immobilie from "@/models/immobilie.model";
import User from "@/models/user";
import { generateBaShareLink } from "@/api/helper/api-helpers";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { openBaseModal } from "@/utilities/modal-helper";
import { Monitoring } from "@/utilities/monitoring";
import { Clipboard } from "@capacitor/clipboard";
import { Share } from "@capacitor/share";
import {
    IonBadge,
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonChip,
    IonDatetime,
    IonDatetimeButton,
    IonIcon,
    IonLabel,
    IonPopover,
    toastController
} from "@ionic/vue";
import {
    addCircle,
    alertCircleOutline,
    archiveOutline,
    arrowDownCircle,
    arrowDownCircleOutline,
    calendarClearOutline,
    calendarOutline,
    checkmarkCircle,
    checkmarkCircleOutline,
    checkmarkDoneCircleOutline,
    clipboardOutline,
    cloudOfflineOutline,
    documentTextOutline,
    ellipseOutline,
    ellipsisHorizontalCircle,
    locationOutline,
    openOutline,
    playCircleOutline,
    shareSocialOutline,
    syncOutline,
    timeOutline,
} from "ionicons/icons";
import moment from "moment";
import { ComputedRef, PropType, computed, defineComponent, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import ProfileIcon from "../Base/ProfileIcon.vue";
import HzbaButton from "./Base/HzbaButton.vue";

export default defineComponent({
  name: "HzbaCard",
  components: {
    AButton,
    PdfChip,
    DownloadSurveyButton,
    AImage,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonButton,
    IonIcon,
    IonLabel,
    IonBadge,
    IonChip,
    IonDatetime,
    IonDatetimeButton,
    IonPopover,
    DateChange,
    ProfileIcon,
    HzbaButton
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    property: {
      type: Object as PropType<Immobilie>,
      required: false,
    },
    uiMode: {
      type: String,
      default: "normal",
    },
    withAddButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['create-survey'],
  setup(props) {
    const organisationPrefix: ComputedRef<string> = computed(() => {
      const currentUser: User | null = User.query().first();
      return currentUser ? currentUser.organisationPrefix : "";
    });

    const store = useStore()
    const { t, locale } = useI18n({ useScope: "global" });
    const { user, canUpdateBegehungsDate, canUpdateSichtungsDate, isImmobilienverwalter } = useUser();
    const router = useRouter();
    const {
       isPerformingDatabaseUpdate,
       changedBegehungsdate,
       changedSichtungsdate,
       saveBegehungsDate,
       saveSichtungsDate
      } = useDateChangeUtils(toRef(props, 'ba'));

    const { isMaxSmall } = useScreenSize();

    const { openChangeStatusAlert, removeDownloadedBa } = useBestandsaufnahmens();
    const { openChangePersonAlert } = usePersonChangeUtils();

    const featureFlags = computed(() => user.value?.organisation?.featureFlags);

    const isMobile = computed(() => {
      return store.getters["app/isMobile"];
    });

    const immobilie = computed(() => {
      return props.property || Immobilie.getters('getPropOrFallbackProp')(props.ba.immobilie) ;
    });

    const gutachter = computed(() => {
      return props.ba.gutachter;
    });

    const persistedBestandsaufnahmes = computed(() => BestandsaufnahmeModel.getters("persistedBestandsaufnahmes"));

    const alreadyInIndexDB = computed(() => {
      if(persistedBestandsaufnahmes.value?.some((item: any) => item.id === props.ba.id)) {
        return true
      } else {
        return false
      }
    });

    const malusColors = computed(() => {
      return store.getters["app/malusColors"];
    });

    const openBericht = (e: any, pdf: any) => {
      e.stopImmediatePropagation();
      openBaseModal("pdf.view", { pdf: pdf });
    };

    const openMaengel = (e: any) => {
      e.stopImmediatePropagation();
      openBaseModal("maengel.uebersicht", { ba: props.ba });
    };

    const relevantMaengellist = computed(() => {
      if (props.ba && Array.isArray(props.ba.mangellists)) {
        return props.ba.mangellists.filter((el: any) => el.eingabeRelevant);
      }
      return [];
    })

    const openMaengelInImmobilie = (e: any) => {
      e.stopImmediatePropagation();
      openBaseModal("maengel.immobilie.uebersicht", { ba: props.ba });
    };

    const recommendedBericht = (ba: any) => {
      if (ba.berichts && ba.berichts.length > 0) {
        const bericht = ba.berichts.find((el: any) =>
          el.kennzeichen.endsWith("-de")
        );
        return bericht && bericht.data;
      }
      return ba.bericht;
    };

    const recommendedMangelliste = (ba: any) => {
      if (ba.mangellists && ba.mangellists.length > 0) {
        // console.log('ba.mangellists: ', ba.mangellists[0].mangelvorlage.kategorie);
      }
      return ba.mangellists;
    };

    const goToBa = async (e: any) => {
      e.stopImmediatePropagation();
      if (!isImmobilienverwalter()) {
        await router.push("/ba/" + props.ba.id );
      }
    };

    const handleChangeBegehungsDate = (event: any) => {
      if (canUpdateBegehungsDate.value) {
        event.stopImmediatePropagation();
      }
    };

    const handleChangeSichtungsDate = (event: any) => {
      if (canUpdateSichtungsDate.value) {
        event.stopImmediatePropagation();
      }
    };

    const handleStatusChange = (e: any) => {
      if(featureFlags?.value.survey?.changeStatus) {
        e.stopImmediatePropagation();
        openChangeStatusAlert(props.ba, t);
      }
    };

    const handlePersonChange = () => {
      if(featureFlags?.value.survey?.changePerson) {
        if (user.value?.organisation?.id) {
          openChangePersonAlert(props.ba);
        } else {
          Monitoring.error("Cannot change person field. User has no organisation id");
        }
      }
    };

    return {
      arrowDownCircle,
      immobilie,
      getPhotoUrl,
      openBericht,
      clipboardOutline,
      documentTextOutline,
      alertCircleOutline,
      arrowDownCircleOutline,
      checkmarkCircle,
      relevantMaengellist,
      ellipseOutline,
      ellipsisHorizontalCircle,
      cloudOfflineOutline,
      shareSocialOutline,
      HzbaStatus,
      calendarOutline,
      calendarClearOutline,
      syncOutline,
      moment,
      locationOutline,
      timeOutline,
      recommendedBericht,
      recommendedMangelliste,
      translatedHzbaStatus,
      t,
      openMaengel,
      openMaengelInImmobilie,
      goToBa,
      handleStatusChange,
      removeDownloadedBa,
      user,
      isImmobilienverwalter,
      malusColors,
      featureFlags,
      persistedBestandsaufnahmes,
      isPerformingDatabaseUpdate,
      changedBegehungsdate,
      changedSichtungsdate,
      saveBegehungsDate,
      saveSichtungsDate,
      locale,
      handleChangeBegehungsDate,
      handleChangeSichtungsDate,
      canUpdateBegehungsDate,
      canUpdateSichtungsDate,
      appPlatform: computed(() => store.state.app.appPlatform),
      isMaxSmall,
      openOutline,
      handlePersonChange,
      gutachter,
      alreadyInIndexDB,
      isMobile,
      addCircle,
      organisationPrefix
    };
  },
  data() {
    return {
      toast: null,
      isDownloading: false
    }
  },
  computed: {
    statusIconState() {
      switch(this.ba.status) {
        case HzbaStatusCode.ANGELEGT: return ellipseOutline;
        case HzbaStatusCode.GEPLANT: return timeOutline;
        case HzbaStatusCode.IN_DURCHFUEHRUNG: return playCircleOutline;
        case HzbaStatusCode.ABGESCHLOSSEN: return checkmarkCircleOutline;
        case HzbaStatusCode.FREIGEGEBEN: return checkmarkDoneCircleOutline;
        case HzbaStatusCode.ARCHIVIERT: return archiveOutline;
        default: return alertCircleOutline
      }
    },
  },
  watch: {
    alreadyInIndexDB() {
      if(this.toast) {
        this.closeToast()
      }
    }
  },
  methods: {
    async downloadBestandsaufnahme() {
      this.isDownloading = true

      try {
        await BestandsaufnahmeModel.dispatch("fetchMoverSurveyById", {surveyId: this.ba.id, saveToStore: true})
        this.isDownloading = false
      } catch(err: any) {
        console.log(err)
        this.isDownloading = false
      }
    },

    async removeFromIndexDB() {
      await this.removeDownloadedBa(this.ba)
    },

    async handleBaShare() {
      const url = generateBaShareLink(this.ba.id)
      if(this.appPlatform === AppPlatform.desktop) {
        await Clipboard.write({
          string: url
        })

        const toast = await toastController.create({
          message: this.t('hzba.toast.urlCopied'),
          duration: 3000,
          cssClass: 'copyUrlToast'
        })

        await toast.present();
      } else {
        await Share.share({
          title: this.t('hzba.shareBa.title'),
          text: this.t('hzba.shareBa.text'),
          url: url,
        });
      }
    },

    async openToast(event: MouseEvent) {
      if(!event.relatedTarget || (event.relatedTarget as HTMLElement).tagName.toLowerCase() === 'ion-icon') {
        // https://stackoverflow.com/questions/37403733/jquery-onclick-triggers-mouseover-on-touch-device
        // triggered by click and not by hover
        return;
      }
      this.toast = await toastController.create({
        message: this.t("hzba.toast.downloadedInfo"),
        duration: 500,
        position: 'bottom',
        cssClass: 'downloadInfoToast'
      });
      await this.toast.present();
    },

    closeToast() {
      this.toast?.dismiss();
    },
  }
});
</script>

<style lang="scss">
.hzbaCard {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--black100);
  }

  p {
    color: var(--black100);
    font-size: 16px;
    font-weight: 400;
  }

  ion-card {
    box-shadow: $boxShadow;
  }

  .alreadyInIndexDB {
    width: 32px;
    height: 32px;

    ion-icon {
      width: 100%;
      height: 100%;
    }
  }

}
ion-toast.downloadInfoToast {
  --background: var(--white100);
  --color: var(--primary);
}

ion-button.open-survey {
  --background: transparent;
  --border-radius: 9999px;
  --box-shadow: 0 0 3px #ccc;
}
</style>
