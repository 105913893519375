import { getClosestNumber } from "@/utilities/math-helper";
import { watchElementDimensions } from "@/utilities/observer-utilities";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { IonModal, onIonViewWillEnter, onIonViewWillLeave } from '@ionic/vue';
import { onMounted, onUnmounted, ref } from "vue";

interface DoubleModalViewOptions {
    keepSecondModalSmaller?: boolean;
    breakpoints?: number[];
    startingBreakpointFirstModal?: number;
    startingBreakpointSecondModal?: number;
    startingBreakpointSecondModalRelative?: number;
}

export default (options?: DoubleModalViewOptions) => {

    let footerHeight: string;
    let disconnectFooterWatcher: (() => void) | null;
    let disconnectHeaderWatcher: (() => void) | null;

    const breakpoints = options?.breakpoints ?? [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
    const initialBreakpointFirstModal = options?.startingBreakpointFirstModal ?? 0.7;

    let initialBreakpointSecondModal = 0.5;

    if (options?.startingBreakpointSecondModal) {
        initialBreakpointSecondModal = options.startingBreakpointSecondModal;
    } else if (options?.startingBreakpointSecondModalRelative && breakpoints.length > 0) {
        initialBreakpointSecondModal = getClosestNumber(breakpoints, initialBreakpointFirstModal * options.startingBreakpointSecondModalRelative) as number;
    }

    const firstModalTemplateRef = ref<InstanceType<typeof IonModal>>();
    const secondModalTemplateRef = ref<InstanceType<typeof IonModal>>();

    const isFirstModalOpen = ref(false);
    const isSecondModalOpen = ref(false);

    const openFirstModal = () => isFirstModalOpen.value = true;
    const hideFirstModal = () => isFirstModalOpen.value = false;
    const toggleFirstModal = () => isFirstModalOpen.value = !isFirstModalOpen.value;
    const openSecondModal = () => isSecondModalOpen.value = true;
    const hideSecondModal = () => isSecondModalOpen.value = false;
    const toggleSecondModal = () => isSecondModalOpen.value = !isSecondModalOpen.value;

    // content of ion-modal does not adapt to the breakpoint position of the modal, which can cause unreachable content if not at max breakpoint position
    // => this function manually adapts the height of content 
    // fow now @ion-breakpoint-did-change is used with this function, some solution with requestAnimationFrame would be more seamless but maybe more error-prone
    // see commit dbe6b4bcb4888e436edc639014f9e9c287e08586
    const adaptContentModalHeight = (element?: HTMLElement) => {
        const ionPage = element?.querySelector(".ion-page") as HTMLElement;
        if (ionPage) {
            const { top } = ionPage.getBoundingClientRect();
            const height = window.innerHeight - (top ?? 0);
            ionPage.style.height = `${height}px`;
        }
    };

    const adaptFirstModalBreakpoint = (breakpointSecondModal: number) => {
        const higherValue = breakpoints?.[breakpoints.indexOf(breakpointSecondModal) + 1] ?? breakpoints[breakpoints.length - 1];
        firstModalTemplateRef.value?.$el.setCurrentBreakpoint(higherValue);
    };

    const adaptSecondModalBreakpoint = (breakpointFirstModal: number) => {
        const smallerValue = breakpoints?.[breakpoints.indexOf(breakpointFirstModal) - 1] ?? hideSecondModal();
        secondModalTemplateRef.value?.$el.setCurrentBreakpoint(smallerValue);
    };

    const onFirstModalBreakpointChange = (event: any) => {
        // TODO: setCurrentBreakpoint seems to bug out
        // if (options?.keepSecondModalSmaller) {
        //     adaptSecondModalBreakpoint(event.detail.value);
        // }
        adaptContentModalHeight(firstModalTemplateRef.value?.$el);
    };

    const onSecondModalBreakpointChange = (event: any) => {
        // if (options?.keepSecondModalSmaller) {
        //     adaptFirstModalBreakpoint(event.detail.value);
        // }
    };

    if (Capacitor.isPluginAvailable("Keyboard")) {
        Keyboard?.addListener('keyboardWillShow', () => {
            document.documentElement.style.setProperty('--bottomToolbarHeight', "0px");
        });

        Keyboard?.addListener('keyboardWillHide', () => {
            document.documentElement.style.setProperty('--bottomToolbarHeight', footerHeight);
        });
    }

    onIonViewWillEnter(() => {
        //TODO: make configurable if modal is opened on view-enter
        hideFirstModal();
        hideSecondModal();
    });

    onIonViewWillLeave(() => {
        hideFirstModal();
        hideSecondModal();
    });

    onMounted(() => {
        disconnectFooterWatcher = watchElementDimensions(document.documentElement.querySelector(".bottomToolbar"), (_width: number, height: number) => {
            firstModalTemplateRef.value?.$el?.style.setProperty("--bottom-toolbar-height", `${height}px`);
            secondModalTemplateRef.value?.$el?.style.setProperty("--bottom-toolbar-height", "0px");
        });

        disconnectHeaderWatcher = watchElementDimensions(document.documentElement.querySelector(".app-header-all-screens"), (_width: number, height: number) => {
            firstModalTemplateRef.value?.$el?.style.setProperty("--top-toolbar-height", `${height}px`);
            secondModalTemplateRef.value?.$el?.style.setProperty("--top-toolbar-height", "0px");
        });
    });

    onUnmounted(() => {
        if (Capacitor.isPluginAvailable("Keyboard")) {
            Keyboard?.removeAllListeners();
        }
        disconnectFooterWatcher?.();
        disconnectHeaderWatcher?.();
    });

    return {
        firstModalTemplateRef,
        secondModalTemplateRef,
        isFirstModalOpen,
        isSecondModalOpen,
        openFirstModal,
        hideFirstModal,
        toggleFirstModal,
        openSecondModal,
        hideSecondModal,
        toggleSecondModal,
        breakpoints,
        initialBreakpointFirstModal,
        initialBreakpointSecondModal,
        onFirstModalBreakpointChange,
        onSecondModalBreakpointChange
    };
};