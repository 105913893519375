<template>
  <ion-content>
    <section-wrapper
      v-if="!surveyMapModal && fragenblock?.path"
      class="mt-4 mx-6 text-xs"
      style="color: #7f7f7f"
    >
      {{ getNestedBreadcrumbTitle(fragenblock.path) }}
    </section-wrapper>
    <section-wrapper>
      <RecursiveForm
        v-if="!surveyMapModal && fragenblock"
        :parent-fragenblock="fragenblock"
        :modal-depth="modalDepth"
      />
      <HzbaForm
        v-if="surveyMapModal"
        :read-only="!isAllowedToEdit"
        :form-frages="fragenblock?.frages ?? []"
      />

      <div>
        <div v-if="surveyMapModal && isAllowedToEdit">
          <SurveyMapFragenblockButtons @delete="$emit('delete')" />
        </div>
        <div v-else-if="!surveyMapModal">
          <hzba-delete-duplicate-item
            v-if="fragenblock && fragenblock.blockTyp && fragenblock.blockTyp.toLowerCase() === 'mehrfach'"
            :fragenblock="fragenblock"
            @delete="$emit('delete')"
            @duplicate="$emit('duplicate')"
          />
        </div>
      </div>
    </section-wrapper>
  </ion-content>
</template>

<script
    setup
    lang="ts"
>

import SectionWrapper from "@/components/Base/SectionWrapper.vue";
import HzbaForm from "@/components/hzba/Base/HzbaForm.vue";
import HzbaDeleteDuplicateItem from "@/components/hzba/Base/Input/HzbaDeleteDuplicateItem.vue";
import RecursiveForm from "@/components/hzba/RecursiveForm.vue";
import SurveyMapFragenblockButtons from '@/components/v2/Map/SurveyMapFragenblockButtons.vue';
import {
    getNestedBreadcrumbTitles,
    useIdentifierFragenblockFinder
} from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import { Fragenblock } from "@/models/ba/Fragenblock";
import { IonContent } from "@ionic/vue";
import { computed } from "vue";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import _ from 'lodash';

const props = defineProps<{
    fragenblockBase: Fragenblock;
    modalDepth: number;
    surveyMapModal: boolean;
}>();

defineEmits(["delete", "duplicate"]);

const { user } = useUser();
const store = useStore();

const currentBa = computed(() => {
    return store.state.currentHzba.currentBa;
});

const fragenblock = computed<Fragenblock | undefined>(() => {
    return currentBa.value && useIdentifierFragenblockFinder(currentBa.value, props.fragenblockBase.path, undefined);
});

const isAllowedToEdit = computed(() => {
    // flag only matters atm for surveyMapModal
    return !props.surveyMapModal || (user.value?.id && fragenblock.value?.config?.userId === user.value.id);
});

const getNestedBreadcrumbTitle = (path: string) => {
    return path && currentBa.value && getNestedBreadcrumbTitles(currentBa.value, path);
}

</script>


<style
    scoped
    lang="scss"
></style>