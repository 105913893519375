import { manageApiCall } from "@/api/api-manager";
import { disconnectAndDeleteFragenblockRecursive, postFragenblock, putFragenblock } from "@/api/fragenblock-api";
import { createAsyncQueue } from "@/api/helper/async-queue";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { Fragenblock } from "@/models/ba/Fragenblock";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import { Monitoring } from "@/utilities/monitoring";

export const fragenStore = {
    namespaced: true,
    state: () => ({
        queuePostFragenblock: createAsyncQueue(),
        queuePutFragenblock: createAsyncQueue(),
        queueDisconnectAndDeleteFragenblock: createAsyncQueue()
    }),
    mutations: {

    },
    actions: {

        async createFragenblockFromFeature(
            { commit, state, rootState }: any,
            { ba, parentFragenblock, drawItem, userId }: { ba: Bestandsaufnahme, parentFragenblock: Fragenblock, drawItem: any, userId: number; }
        ) {

            if (!parentFragenblock.id) {
                Monitoring.withScope(scope => {
                    scope.setContext("Function parameters", { SurveyId: ba.id, Path: parentFragenblock.identifier });
                    Monitoring.error("Unexpected Error while creating Fragenblock from draw feature: parentFragenblock has no id");
                });
                return null;
            }

            await parentFragenblock.addFragenblockInstance(parentFragenblock.fragenblocks[0]);
            const newlyCreated = parentFragenblock.fragenblocks[parentFragenblock.fragenblocks.length - 1];

            newlyCreated.config = {
                ...newlyCreated.config,
                userId
            };
            newlyCreated.createdAt = new Date().toISOString();

            if (newlyCreated.frages && newlyCreated.frages.length > 0) {
                const firstFrage = newlyCreated?.frages[0];
                firstFrage.eingabeText = newlyCreated.getTitle() + " - " + (parentFragenblock.fragenblocks.length - 1).toString();
            }
            newlyCreated.geoJson = drawItem;

            const fallbackValue = { data: { fragenblock: newlyCreated } };

            const apiCall = manageApiCall(postFragenblock, {
                onStart: () => commit('app/setLoadingSpinner', true, { root: true }),
                retryOptions: { retries: 5 },
                queue: state.queuePostFragenblock,
                errorMessage: "Error while creating Fragenblock from draw feature",
                failHandlers: {
                    fallbackValue,
                    onFail: () => BestandsaufnahmeModel.dispatch("markWithUnsavedChanges", ba),
                    onFinally: () => commit('app/setLoadingSpinner', false, { root: true })
                },
                timeout: {
                    time: 35000,
                    message: "Creating Fragenblock from draw feature timed out",
                    onTimeout: () => BestandsaufnahmeModel.dispatch("markWithUnsavedChanges", ba)
                },
                preRequestCheck: {
                    check: () => rootState.app.networkConnected && !!parentFragenblock.id,
                    onAbort: () => BestandsaufnahmeModel.dispatch("markWithUnsavedChanges", ba), //TODO: do something else?
                    fallbackValue,
                }
            });

            const result = await apiCall.makeRequest({
                newFragenblock: await newlyCreated.toClassJson(),
                parentFragenblockId: parentFragenblock.id,
            });

            if (result?.data?.fragenblock?.id) {
                newlyCreated.injectIds(result.data.fragenblock);
            }
            return newlyCreated;
        },

        async addFragenblock(
            { commit, state, rootState }: any,
            { ba, parentFragenblock, fragenblock }: { ba: Bestandsaufnahme, parentFragenblock: Fragenblock, fragenblock: Fragenblock; }
        ) {
            if (!parentFragenblock.id) {
                Monitoring.withScope(scope => {
                    scope.setContext("Function parameters", { SurveyId: ba.id, ParentId: parentFragenblock.id });
                    Monitoring.error("Unexpected Error while adding Fragenblock: parentFragenblock has no id");
                });
                return null;
            }

            await parentFragenblock.addFragenblockInstance(fragenblock);
            const newFragenblock = parentFragenblock.fragenblocks[parentFragenblock.fragenblocks.length - 1];

            const fallbackValue = { data: { fragenblock: newFragenblock } };

            const apiCall = manageApiCall(postFragenblock, {
                onStart: () => commit('app/setLoadingSpinner', true, { root: true }),
                retryOptions: { retries: 5 },
                queue: state.queuePostFragenblock,
                errorMessage: "Error while adding Fragenblock",
                failHandlers: {
                    fallbackValue,
                    onFail: () => BestandsaufnahmeModel.dispatch("markWithUnsavedChanges", ba),
                    onFinally: () => commit('app/setLoadingSpinner', false, { root: true })
                },
                timeout: {
                    time: 35000,
                    message: "Adding Fragenblock timed out",
                    onTimeout: () => BestandsaufnahmeModel.dispatch("markWithUnsavedChanges", ba)
                },
                preRequestCheck: {
                    check: () => rootState.app.networkConnected,
                    onAbort: () => BestandsaufnahmeModel.dispatch("markWithUnsavedChanges", ba),
                    fallbackValue
                }
            });

            const result = await apiCall.makeRequest({
                newFragenblock: await newFragenblock.toClassJson(),
                parentFragenblockId: parentFragenblock.id,
            });

            if (result?.data?.fragenblock?.id) {
                newFragenblock.injectIds(result.data.fragenblock);
            }
            return newFragenblock;
        },

        async duplicateFragenblock(
            { commit, state, rootState }: any,
            { ba, parentFragenblock, fragenblock }: { ba: Bestandsaufnahme, parentFragenblock: Fragenblock, fragenblock: Fragenblock; }
        ) {
            if (!parentFragenblock.id) {
                Monitoring.withScope(scope => {
                    scope.setContext("Function parameters", { SurveyId: ba.id, ParentId: parentFragenblock.id, FragenblockToDuplicateId: fragenblock.id });
                    Monitoring.error("Unexpected Error while duplicating Fragenblock: Missing id");
                });
                return null;
            }

            await parentFragenblock.duplicateFragenblockInstance(fragenblock);
            const duplicatedFragenblock = parentFragenblock.fragenblocks[parentFragenblock.fragenblocks.length - 1];

            const fallbackValue = { data: { fragenblock: duplicatedFragenblock } };

            const apiCall = manageApiCall(postFragenblock, {
                onStart: () => commit('app/setLoadingSpinner', true, { root: true }),
                retryOptions: { retries: 5 },
                queue: state.queuePostFragenblock,
                errorMessage: "Error while duplicating Fragenblock",
                failHandlers: {
                    fallbackValue,
                    onFail: () => BestandsaufnahmeModel.dispatch("markWithUnsavedChanges", ba),
                    onFinally: () => commit('app/setLoadingSpinner', false, { root: true })
                },
                timeout: {
                    time: 35000,
                    message: "Duplicating Fragenblock timed out",
                    onTimeout: () => BestandsaufnahmeModel.dispatch("markWithUnsavedChanges", ba)
                },
                preRequestCheck: {
                    check: () => rootState.app.networkConnected,
                    onAbort: () => BestandsaufnahmeModel.dispatch("markWithUnsavedChanges", ba),
                    fallbackValue
                }
            });

            const result = await apiCall.makeRequest({
                newFragenblock: await duplicatedFragenblock.toClassJson(),
                parentFragenblockId: parentFragenblock.id,
            });

            if (result?.data?.fragenblock?.id) {
                duplicatedFragenblock.injectIds(result.data.fragenblock);
            }
            return duplicatedFragenblock;
        },

        async updateFragenblock(
            { commit, state, rootState }: any,
            { ba, fragenblock, updateFields }: { ba: Bestandsaufnahme, fragenblock: Fragenblock, updateFields: Record<string, any>; }
        ) {
            const fallbackValue = { data: { fragenblock } };

            const apiCall = manageApiCall(putFragenblock, {
                onStart: () => commit('app/setLoadingSpinner', true, { root: true }),
                retryOptions: { retries: 5 },
                queue: state.queuePutFragenblock,
                errorMessage: "Error updating Fragenblock",
                failHandlers: {
                    fallbackValue,
                    onFail: () => BestandsaufnahmeModel.dispatch("markWithUnsavedChanges", ba),
                    onFinally: () => commit('app/setLoadingSpinner', false, { root: true })
                },
                timeout: {
                    time: 35000,
                    message: "Updating Fragenblock timed out",
                    onTimeout: () => BestandsaufnahmeModel.dispatch("markWithUnsavedChanges", ba)
                },
                preRequestCheck: {
                    check: () => rootState.app.networkConnected,
                    onAbort: () => BestandsaufnahmeModel.dispatch("markWithUnsavedChanges", ba),
                    fallbackValue
                }
            });

            const result = await apiCall.makeRequest({ fragenblockFields: { ...updateFields, id: fragenblock.id } });
            return result?.data?.fragenblock;
        },

        async disconnectAndDeleteFragenblock(
            { commit, state, rootState }: any,
            { ba, parentFragenblock, fragenblock }: { ba: Bestandsaufnahme, parentFragenblock: Fragenblock, fragenblock: Fragenblock; }
        ) {
            if (!parentFragenblock.id || (!fragenblock.id && !fragenblock.uid)) {
                Monitoring.withScope(scope => {
                    scope.setContext("Function parameters", { SurveyId: ba.id, ParentId: parentFragenblock.id, FragenblockToDeleteId: fragenblock.id });
                    Monitoring.error("Unexpected Error while disconnecting and deleting Fragenblock: Missing id");
                });
                return null;
            }

            await parentFragenblock.deleteFragenblockInstance(fragenblock);

            if(!fragenblock.id) {
                return null;
            }

            const apiCall = manageApiCall(disconnectAndDeleteFragenblockRecursive, {
                onStart: () => commit('app/setLoadingSpinner', true, { root: true }),
                retryOptions: { retries: 5 },
                errorMessage: "Error disconnecting Fragenblock",
                queue: state.queueDisconnectAndDeleteFragenblock,
                failHandlers: {
                    fallbackValue: null,
                    onFail: () => BestandsaufnahmeModel.dispatch("markWithUnsavedChanges", ba),
                    onFinally: () => commit('app/setLoadingSpinner', false, { root: true })
                },
                timeout: {
                    time: 35000,
                    message: "Disconnecting Fragenblock timed out",
                    onTimeout: () => BestandsaufnahmeModel.dispatch("markWithUnsavedChanges", ba)
                },
                preRequestCheck: {
                    fallbackValue: null,
                    check: () => rootState.app.networkConnected,
                    onAbort: () => BestandsaufnahmeModel.dispatch("markWithUnsavedChanges", ba)
                }
            });

            apiCall.makeRequest({ id: fragenblock.id, parentId: parentFragenblock.id });
        }
    }
};
