import { AppConfig } from "@/composables/useAppConfig";
import { AppInfoInterface, AppPlatform } from "@/composables/useAppInfo";
import { CreateSurveyDateInterface } from "@/composables/useCreateSurvey";
import { lang } from "@/models/localization.model";
import { createAsyncQueue } from "@/api/helper/async-queue";
import { SCREEN_LG_START } from "@/utilities/constants";
import { handleOfflineData } from "@/utilities/handle-offline-data";
import { Monitoring } from "@/utilities/monitoring";
import { UseIonRouterResult } from "@ionic/vue";

const handleOfflineDataQeue = createAsyncQueue();

export const app = {
  namespaced: true,
  state: () => ({
    appConfig: {} as AppConfig,
    appPlatform: '' as AppPlatform,
    appInfo: {} as AppInfoInterface,
    createSurvey: {} as CreateSurveyDateInterface,
    locale: 'de' as lang,
    t: {} as any,
    showBottomNavigation: false,
    screenWidth: -1,
    screenHeight: -1,
    networkConnected: undefined,
    isRecordGeometryOpen: false,
    socketConnected: null as boolean | null, // using false and null to distinguish between disconnected and not-connected-initially
    recentRoute: undefined,
    currentRoute: undefined,
    deviceId: undefined,
    consoleLogs: [],
    lastBestandsaufnahmesBackendRefresh: undefined,
    showAppLinkBanner: false,
    welcomeAnimationDone: false,
    enabledDevMode: false, // for debugging only
    enabledDevModeLogging: false, // for better logs set this to true.
    disabledDevOTA: false, // always set to false when deploying to dev/uat/prod
    isLoadingSpinner: false,
    styleConfig: {
      malusColors: {
        "1": "green",
        "2": "blue",
        "3": "yellow",
        "4": "red",
      }
    },
    heading: -1, // The orientation/direction/heading of the device relative to the north. Is not read constantly, only when taking photos
    deviceOrientationListener: null as any,
    openedPropertyId: null as number | null, // this is used only in the property map, is null when when anywhere else
    currentPropertyId: null as number | null, // this one tracks the current property id on all components
    router: null as UseIonRouterResult | null
  }),
  mutations: {
    setAppConfig(state: any, data: AppConfig) {
      state.appConfig = data
    },
    setAppPlatform(state: any, platform: AppPlatform) {
      state.appPlatform = platform;
    },
    setAppInfo(state: any, appInfo: AppInfoInterface) {
      state.appInfo = appInfo;
    },
    setCreateSurveyData(state: any, data: CreateSurveyDateInterface) {
      state.createSurvey = data
    },
    showBottomNavigation(state: any) {
      state.showBottomNavigation = true;
    },
    hideBottomNavigation(state: any) {
      state.showBottomNavigation = false;
    },
    setScreenSize(state: any, { width, height }: { width: number; height: number }) {
      state.screenWidth = width;
      state.screenHeight = height;
    },
    setNetworkState(state: any, networkConnected: boolean) {
      state.networkConnected = networkConnected;
    },
    setSocketConnectionState(state: any, connected: boolean) {
      state.socketConnected = connected;
    },
    setRecentRoute(state: any, from: any) {
      state.recentRoute = from;
    },
    setCurrentRoute(state: any, to: any) {
      state.currentRoute = to;
    },
    setDeviceId(state: any, from: any) {
      state.deviceId = from;
    },
    setShowAppLinkBanner(state: any, bool: any) {
      state.showAppLinkBanner = bool;
    },
    setLocale(state: any, lang: lang) {
        // LOCALE FIXED TO "de"
        lang = "de";
        localStorage.setItem('locale', lang);
        state.locale = lang;
        console.log('[app/setLocale] selected language:', lang);
    },
    updateBestandsaufnahmesLastRefresh(state: any) {
      state.lastBestandsaufnahmesBackendRefresh = new Date();
    },

    addConsoleLog(state: any, from: any) {
      state.consoleLogs.push({
        date: new Date(),
        logs: from
      });
    },
    setWelcomeAnimationDone(state: any, bool: boolean) {
      state.welcomeAnimationDone = bool;
    },
    setLoadingSpinner(state: any, newValue: boolean) {
      state.isLoadingSpinner = newValue;
    },
    setHeading(state: any, heading: number) {
      state.heading = heading;
    },
    setRecordGeometryOpen(state: any, isOpen: boolean) {
        state.isRecordGeometryOpen = isOpen;
    },
    setDeviceOrientationListener(state: any, listener: any) {
      state.deviceOrientationListener = listener;
    },
    setOpenedPropertyId(state: any, id: number | null) {
      state.openedPropertyId = id;
    },
    setCurrentPropertyId(state: any, id: number | null) {
      state.currentPropertyId = id;
    },
    seti18n(state: any, t: any) {
      state.t = t;
    },
    setRouter(state: any, router: UseIonRouterResult) {
      state.router = router;
    }
  },
  actions: {
      stopRecordingMotion({ commit, state }: any) {
        commit("app/setHeading", -1);
        //Motion.removeAllListeners(); //do not use this, as it does not allow for re-attachment of the listener
        state.deviceOrientationListener?.remove?.();
      },
      setRecordGeometryOpen({ commit }: any, isOpen: boolean) {
        commit('setRecordGeometryOpen', isOpen);
      },
      setOpenedPropertyId({ commit, dispatch }: any, id: number | null) {  
        commit('setOpenedPropertyId', id);
        if(id) {
          dispatch('setCurrentPropertyId', id);
        }
      },
      setCurrentPropertyId({ commit }: any, id: number | null) {  
        commit('setCurrentPropertyId', id);
      },
      queueOfflineDataHandling() {
        handleOfflineDataQeue.enqueue(handleOfflineData).catch((error: any) => {
          Monitoring.chainError('Error in handleOfflineData', error);
        });
        Monitoring.trackOccurences("handleOfflineData");
      },
      setRouter({ commit }: any, router: any) {
        commit('setRouter', router);
      }
  },
  getters: {
    isOffline(state: any, getters: any, rootState: any): boolean {
      return !state.networkConnected;
    },
    isOnline(state: any, getters: any, rootState: any): boolean {
      return state.networkConnected;
    },
    isSocketDisconnected(state: any): boolean {
      return !state.socketConnected;
    },
    isSocketConnected(state: any): boolean {
      return state.socketConnected;
    },
    getLocale(state: any, getters: any, rootState: any): lang {
      return state.locale;
    },
    deviceId(state: any, getters: any, rootState: any): boolean {
      return state.deviceId;
    },
    malusColors(state: any): any {
      return state.styleConfig.malusColors;
    },
    isMobile(state: any): boolean {
      const width = state.screenWidth;
      return state.appPlatform === AppPlatform.native || (width !== -1 && width < SCREEN_LG_START);
    },
    openedPropertyId(state: any): number | null {
      return state.openedPropertyId;
    }
  },
};
