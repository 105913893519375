import { BestandsaufnahmeJson } from "@/models/ba/interfaces/IBestandsaufnahme";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import { Response } from "@vuex-orm/plugin-axios";

/*
* This file contains helper functions for the data model API
*/

/** 
    * Update Bestandsaufnahme - Updates only the specified fields in the backend and returns only the values of all primitive fields as response via API.
    * If the updated field is a complex field like a relation this field is also being returned.
    * Uses VuexORMAxios but does not save the response to the store, please handle manually.
    * Pass optional JSON of existing survey to merge the new data with the old data in order to not lose values of complex fields like Fragenblocks
    * @param {number} baId The ID of the survey/BA
    * @param {number} projectId For access control
    * @param {any} data the fields to update
    * @param {BestandsaufnahmeJson} oldDataToMerge The old data of the survey to merge with the new data
    * @returns {Promise<BestandsaufnahmeJson>} The new survey as JSON
    * @throws Error If the API call fails or the response status is not 200
    */
export const putBestandsaufnahme = async (baId: number, projectId: number, data: any, oldDataToMerge?: BestandsaufnahmeJson) => {
    let response: Response;
    try {
        response = await BestandsaufnahmeModel.api().put(`/bestandsaufnahmes/updateFields/${baId}?projectId=${projectId}`, { data }, { save: false });
    } catch (error: any) {
        throw new Error("Unsuccessful request: " + error.response.statusText); // TODO: improve error message
    }

    if (response.response.status !== 200) {
        throw new Error("Unsuccessful request: " + response.response.statusText);
    }

    const responseSurvey = (response.getDataFromResponse() as any).data as BestandsaufnahmeJson;
    if (oldDataToMerge) {
        return Object.assign({}, oldDataToMerge, responseSurvey);
    } else {
        return responseSurvey;
    }
};