import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79045214"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "propertyMapWrapper block lg:flex map-height" }
const _hoisted_2 = {
  key: 0,
  class: "sidebar ios-fix"
}
const _hoisted_3 = { class: "properties-sidebar-wrapper" }
const _hoisted_4 = { class: "properties-sidebar" }
const _hoisted_5 = { class: "relative w-full map-height" }
const _hoisted_6 = {
  key: 0,
  class: "modal-detail-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SafeArea = _resolveComponent("SafeArea")!
  const _component_PropertyCompactList = _resolveComponent("PropertyCompactList")!
  const _component_PropertyMapFilter = _resolveComponent("PropertyMapFilter")!
  const _component_CreatePropertyButton = _resolveComponent("CreatePropertyButton")!
  const _component_AppMap = _resolveComponent("AppMap")!
  const _component_PropertyDetail = _resolveComponent("PropertyDetail")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SafeArea, { position: "top" }),
    (_ctx.isMinLarge)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_PropertyCompactList, {
                  properties: _ctx.properties,
                  "selected-property": _ctx.selectedProperty,
                  "selected-property-id": _ctx.selectedPropertyId,
                  "diplay-search-results": _ctx.diplaySearchResults,
                  "style-classes": "desktop-content-container",
                  onOpen: _ctx.openSelectedProperty
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                  ]),
                  _: 3
                }, 8, ["properties", "selected-property", "selected-property-id", "diplay-search-results", "onOpen"])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_PropertyMapFilter),
      (_ctx.isViewEntered)
        ? (_openBlock(), _createBlock(_component_AppMap, {
            key: 0,
            identifier: "propertyMap",
            "map-settings": _ctx.currentProject.mapConfig.map,
            style: {"width":"100%"},
            class: "map-height",
            "onMap:loaded": _ctx.handleMapLoaded,
            "onMap:zoomOverview": _cache[0] || (_cache[0] = ($event: any) => (_ctx.propertyMap.zoomToOverview(_ctx.currentProject.mapConfig.map))),
            "onPoint:click": _ctx.pointClicked
          }, {
            default: _withCtx(() => [
              (!_ctx.isMobile)
                ? (_openBlock(), _createBlock(_component_CreatePropertyButton, {
                    key: 0,
                    class: "absolute bottom-3 right-3 z-10",
                    onClick: _ctx.openCreateProperty
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["map-settings", "onMap:loaded", "onPoint:click"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isMobile)
      ? (_openBlock(), _createBlock(_component_ion_modal, {
          key: 1,
          ref: "modalDetailPage",
          class: "modal-style show-as-foreground-modal",
          "is-open": _ctx.isMobile && _ctx.showModal && _ctx.openedPropertyId,
          "backdrop-dismiss": false,
          "can-dismiss": true,
          "initial-breakpoint": _ctx.initialBreakpointDetailPage,
          breakpoints: _ctx.breakpoints,
          "backdrop-breakpoint": 1.0,
          "keep-contents-mounted": true,
          onIonBreakpointDidChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onBreakpointChangeDetailModal($event)))
        }, {
          default: _withCtx(() => [
            (_ctx.isPropertyDetailPageInitialized)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_PropertyDetail)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["is-open", "initial-breakpoint", "breakpoints"]))
      : _createCommentVNode("", true),
    (_ctx.isMobile)
      ? (_openBlock(), _createBlock(_component_ion_modal, {
          key: 2,
          ref: "modal",
          class: "modal-style show-as-background-modal",
          "is-open": _ctx.isMobile && _ctx.showModal,
          "backdrop-dismiss": false,
          "can-dismiss": true,
          "initial-breakpoint": _ctx.initialBreakpoint,
          breakpoints: _ctx.breakpoints,
          "backdrop-breakpoint": 1.0,
          "keep-contents-mounted": true,
          onIonBreakpointDidChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onBreakpointChange($event))),
          onDidPresent: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onModalDidPresent())),
          onDidDismiss: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onModalDidDismiss()))
        }, {
          default: _withCtx(() => [
            (_ctx.isModalInitialized)
              ? (_openBlock(), _createBlock(_component_CreatePropertyButton, {
                  key: 0,
                  class: "floating-button z-10",
                  onClick: _ctx.openCreateProperty
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.isModalInitialized)
              ? (_openBlock(), _createBlock(_component_PropertyCompactList, {
                  key: 1,
                  properties: _ctx.properties,
                  "selected-property": _ctx.selectedProperty,
                  "selected-property-id": _ctx.selectedPropertyId,
                  "diplay-search-results": _ctx.diplaySearchResults,
                  "style-classes": "modal-content-container",
                  onOpen: _ctx.openSelectedProperty
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                  ]),
                  _: 3
                }, 8, ["properties", "selected-property", "selected-property-id", "diplay-search-results", "onOpen"]))
              : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["is-open", "initial-breakpoint", "breakpoints"]))
      : _createCommentVNode("", true)
  ]))
}