import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import { Frage } from '@/models/ba/Frage';
import { Fragenblock } from '@/models/ba/Fragenblock';
import { Monitoring } from '@/utilities/monitoring';

export function useIdentifierFinder(ba: Bestandsaufnahme, pfad: string, foundNextCallback?: Function ): Frage | Fragenblock | undefined {
  const pfads = pfad.split('.');
  let currentEl: any = ba;

  // ignore first element as this is the identifier "hzba"
  for (let i = 1; i < pfads.length; i++) {
    const targetPathElUid =
      pfads[i].includes('[') &&
      pfads[i].substring(pfads[i].indexOf('[') + 1, pfads[i].lastIndexOf(']'));
    const targetPathRaw = pfads[i].replace(`[${targetPathElUid}]`, '');

    let tmpEl = undefined;
    // console.log("useidentifierfragefinder found element:", pfad, currentEl, pfads[i])

    if (targetPathElUid === 'FIRST_INSTANCE') {
      const elements = currentEl.getMultipleFragenblockInstances(targetPathRaw);
      tmpEl = elements && elements.length > 0 && elements[0];
    } else {
      /** Find instances via UID **/
      tmpEl = currentEl?.fragenblocks?.find((el: Fragenblock) => {
        return (
          el.identifier === targetPathRaw &&
          (!targetPathElUid || targetPathElUid === el.uid)
        );
      });
    }

    if (!tmpEl) {
      if (i !== pfads.length - 1) {
        if (targetPathElUid === 'FIRST_INSTANCE') {
          // Do not throw an error if we search for first instance as the path might be correct but there is no instance.
          return;
        }
        if (pfads[i].includes('anlagenkomponenten2')) {
          // Do not throw an error, this path doesn't exist on older ba templates
          return;
        }

        // TODO: currently throws an error each time getAnlangenKomponentenBilder in useHzbaPdfReport.ts is called! Reason:
        // Function needs to be compatible with different versions of ba templates with different paths
        // Error should only be throwns if none of the possible paths are found
        console.warn(
          `[ElementFinder] Element not found. Path might be incorrect. Could not find Fragenblock with Identifier '${pfads[i]}'. (TargetPathElUid: ${targetPathElUid})`,
            '\n',
            '\n',
            `Path: '${pfad}'`,
            '\n',
            `Identifier: '${pfads[i]}'`,
            '\n',
            '\n',
            'Last found Element:',
            currentEl,
        );
        return;
      }

      tmpEl = currentEl.frages?.find(
        (el: any) => el.identifier === targetPathRaw,
      );
      // console.log("useidentifierfragefinder found element:", pfad, tmpEl, tmpEl.getCurrentInput())
      foundNextCallback && foundNextCallback(currentEl);
      return tmpEl;
    }

    if (!tmpEl) {
      Monitoring.error(
        'Mangel Setup Error: Path could not be mapped to json and failed at index ' +
          i,
        pfads,
      );
    } else {
      const prevEl = currentEl;
      currentEl = tmpEl;
      foundNextCallback && foundNextCallback(currentEl, prevEl);
    }
  }

  console.log("useidentifierfragefinder found element:", pfad, currentEl)
  return currentEl;
}

export function getNestedBreadcrumbTitles(ba: Bestandsaufnahme, pfad: string, ignoreFirst = true): string {
  let title = '';
  let isFirst = ignoreFirst;
  useIdentifierFinder(ba, pfad, (el: any, prevEl: any) => {
    if (isFirst) {
      isFirst = false;
      return;
    }

    if (title.length > 0) {
      title += ' > ';
    }

    title += el.getTitle();

    // Adding index nr. to breadcrumbs here:
    if (el.isInstanceOfMultiple()) {
      const sameFragenblocks = prevEl.getMultipleFragenblockInstances(
        el.identifier,
      );
      if (!sameFragenblocks) {
        Monitoring.error(
          'useIdentifierFinder error: no fragenblocks found but there are expected some.',
        );
        return;
      }

      for (let i = 0; i < sameFragenblocks.length; i++) {
        const currEl = sameFragenblocks[i];

        if (currEl === el) {
          title += ' ' + (i + 1);
          break;
        }
      }
    }
  });

  return title;
}

/**
 * Adds the uid's of the originPath to the targetPath, if the path is the same.
 * @param searchPath defined in the template without any uid's. like the following: ba.heizzentrale.heizkreis.another-group.mag
 * @param originPath Method is called from an object with this path. Instantiated by the App, this path includes uid's like the following: ba.heizzentrale.heizkreis[123e4].another-group.mag[1043k8]
 *
 * Example A:
 * targetPath:  ba.heizzentrale.heizkreis.another-group.fieldA
 * originPath:  ba.heizzentrale.heizkreis[123e4].another-group.mag[1043k8]
 * result:      ba.heizzentrale.heizkreis[123e4].another-group.fieldA
 *
 * Example B:
 * targetPath:  ba.heizzentrale.heizkreis.another-group.mag.fieldB
 * originPath:  ba.heizzentrale.heizkreis[123e4].another-group.mag[1043k8]
 * result:      ba.heizzentrale.heizkreis[123e4].another-group.mag[1043k8].fieldB
 *
 * Not supported (yet) Example C:
 * targetPath:  ba.heizzentrale.heizkreis.another-group.mag.fieldD
 * originPath:  ba.heizzentrale.heizkreis[123e4].fieldC
 * result:      ???
 *
 */
export function mergeTargetPathAndOriginPath(targetPath: string, originPath?: string,) {
  if (!originPath) {
    return targetPath;
  }

  const targetPaths = targetPath.split('.');
  const originPaths = originPath.split('.');
  let stillSynced = true;

  let resultPath = '';

  for (let i = 1; i < targetPaths.length; i++) {
    resultPath += '.' + targetPaths[i];

    if (originPaths.length - 1 < i) {
      // origin path ended here so we do not add anything else to the path.
    } else {
      // console.log("merge target path", originPaths, originPath, i, originPaths.length);
      const originPathElUid =
        originPaths[i].includes('[') &&
        originPaths[i].substring(
          originPaths[i].indexOf('[') + 1,
          originPaths[i].lastIndexOf(']'),
        );
      const originPathRaw =
        originPaths && originPaths[i].replace(`[${originPathElUid}]`, '');

      if (originPathElUid && stillSynced && originPathRaw === targetPaths[i]) {
        resultPath += '[' + originPathElUid + ']';
      } else if (originPathRaw !== targetPaths[i]) {
        stillSynced = false;
      }
    }
  }

  const result = targetPaths[0] + resultPath;

  /**
     * Keep this for debugging:
        console.log('mergeTargetPathAndOriginPath -------')
        console.log('mergeTargetPathAndOriginPath TargetPath', targetPath)
        console.log('mergeTargetPathAndOriginPath OriginPath', originPath)
        console.log('mergeTargetPathAndOriginPath ResultPath', result)
     */

  return result;
}

export function getAuswahlLabel(
  ba: Bestandsaufnahme,
  searchPath: string,
  fromPath?: string,
  foundNextCallback?: Function
): string | undefined {
  const frageObject: any = useIdentifierFrageFinder(
    ba,
    searchPath,
    fromPath,
    foundNextCallback
  );

  const selectedOption = frageObject.auswahlOptions?.find(
    (option: any) => option.wert === frageObject.eingabeAuswahlWert
  );

  return selectedOption?.label?.de;
}

export function useIdentifierNodeFinder(ba: Bestandsaufnahme, searchPath: string, fromPath?: string, foundNextCallback?: Function ): Frage | Fragenblock | undefined {
  return useIdentifierFinder(ba, mergeTargetPathAndOriginPath(searchPath, fromPath), foundNextCallback);
}

export function useIdentifierFrageFinder(ba: Bestandsaufnahme, searchPath: string, fromPath?: string, foundNextCallback?: Function ): Frage | undefined {
  return useIdentifierFinder(ba, mergeTargetPathAndOriginPath(searchPath, fromPath), foundNextCallback) as Frage | undefined;
}

export function useIdentifierFragenblockFinder(ba: Bestandsaufnahme, searchPath: string, fromPath?: string, foundNextCallback?: Function ): Fragenblock | undefined {
  return useIdentifierFinder(ba, mergeTargetPathAndOriginPath(searchPath, fromPath), foundNextCallback) as Fragenblock | undefined;
}


export function identifyFragenblockWithIndices(ba: Bestandsaufnahme, pfad: string, foundNextCallback?: Function ): Frage | Fragenblock | undefined {
    const pfads = pfad.split('.');
    let currentEl: any = ba;

    // ignore first element as this is the identifier "hzba"
    for (let i = 1; i < pfads.length; i++) {
      const targetPathElUid =
        pfads[i].includes('[') &&
        pfads[i].substring(pfads[i].indexOf('[') + 1, pfads[i].lastIndexOf(']'));
      const targetPathRaw = pfads[i].replace(`[${targetPathElUid}]`, '');

      let tmpEl = undefined;
      // console.log("useidentifierfragefinder found element:", pfad, currentEl, pfads[i])
      console.log("targetPathElUid targetPathRaw MYLOG", targetPathElUid, targetPathRaw)
      if (targetPathElUid === 'FIRST_INSTANCE') {
        const elements = currentEl.getMultipleFragenblockInstances(targetPathRaw);
        tmpEl = elements && elements.length > 0 && elements[0];
      } else {
        /** Find instances via UID **/
        tmpEl = currentEl?.fragenblocks?.find((el: Fragenblock) => {
          return (
            el.identifier === targetPathRaw
            // !!!!! THIS IS THE ONLY DIFFERENCE COMPARED TO useIdentifierFinder !!!!!
               &&
              (!targetPathElUid || targetPathElUid === String(el.arrayPosition))
          );
        });
      }

      if (!tmpEl) {
        if (i !== pfads.length - 1) {
          if (targetPathElUid === 'FIRST_INSTANCE') {
            // Do not throw an error if we search for first instance as the path might be correct but there is no instance.
            return;
          }
          if (pfads[i].includes('anlagenkomponenten2')) {
            // Do not throw an error, this path doesn't exist on older ba templates
            return;
          }

          // TODO: currently throws an error each time getAnlangenKomponentenBilder in useHzbaPdfReport.ts is called! Reason:
          // Function needs to be compatible with different versions of ba templates with different paths
          // Error should only be throwns if none of the possible paths are found
          console.warn(
            `[ElementFinder] Element not found. Path might be incorrect. Could not find Fragenblock with Identifier '${pfads[i]}'. (TargetPathElUid: ${targetPathElUid})`,
              '\n',
              '\n',
              `Path: '${pfad}'`,
              '\n',
              `Identifier: '${pfads[i]}'`,
              '\n',
              '\n',
              'Last found Element:',
              currentEl,
          );
          return;
        }

        tmpEl = currentEl.frages?.find(
          (el: any) => el.identifier === targetPathRaw,
        );
        // console.log("useidentifierfragefinder found element:", pfad, tmpEl, tmpEl.getCurrentInput())
        foundNextCallback && foundNextCallback(currentEl);
        return tmpEl;
      }

      if (!tmpEl) {
        Monitoring.error(
          'Mangel Setup Error: Path could not be mapped to json and failed at index ' +
            i,
          pfads,
        );
      } else {
        const prevEl = currentEl;
        currentEl = tmpEl;
        foundNextCallback && foundNextCallback(currentEl, prevEl);
      }
    }

    // console.log("useidentifierfragefinder found element:", pfad, currentEl)
    return currentEl;
  }

  export function getFragenblockWithIndices(ba: Bestandsaufnahme, searchPath: string, fromPath?: string, foundNextCallback?: Function ): Fragenblock | undefined {
    return identifyFragenblockWithIndices(ba, mergeTargetPathAndOriginPath(searchPath, fromPath), foundNextCallback) as Fragenblock | undefined;
}