<template>
  <div
    :id="identifier"
    ref="mapContainer"
    class="mapElement"
    :class="{ 'custom-geolocate-style': isRecordGeometryEnabled }"
  >
    <GeoLayerToggle
      v-if="showGeolayerToggle"
      :map="map"
      :is-preload-visible="identifier === 'propertyMap'"
      :map-style="downloadedStyle"
    />
    <FocusController
      v-if="showFocusController"
      :map="map"
      :settings="props.mapSettings"
    />
    <ion-button
      v-if="identifier === 'surveyMap'"
      class="left-button maplibre-like-button open-list-button"
      :class="[{ visible: isGeodataVisible }, { active: geoDataListActive }]"
      size="small"
      :title="$t('appmap.geoDataList')"
      @click="toggleList"
    >
      <ion-icon
        slot="icon-only"
        :icon="listOutline"
      />
    </ion-button>
    <div>
      <div
        v-if="identifier !== 'surveyMap'"
        :class="['calculation-box', { visible: isGeodataVisible }]"
      >
        <div
          id="info"
          v-html="geodataInfo"
        />
      </div>
    </div>
    <div
      v-if="isGeolocateControlActive && currentAccuracy"
      class="accuracy-box"
    >
      <p><b>{{ t("appmap.accuracy") }}:</b> {{ currentAccuracy }} m</p>
    </div>
    <ion-button
      v-if="identifier === 'propertyMap'"
      :title="$t('appmap.zoomtoall')"
      class="zoomtoall-button"
      size="small"
      color="light"
      @click="emit('map:zoomOverview')"
    >
      <ion-icon
        slot="icon-only"
        :icon="scanOutline"
      />
    </ion-button>
    <ion-button
      v-if="isRotateEnabled"
      class="maplibre-like-button rotate-button right-button"
      size="small"
      @click="toggleRotateMode"
    >
      <ion-icon
        slot="icon-only"
        :icon="reloadOutline"
      />
    </ion-button>
    <ion-button
      v-if="isRecordGeometryEnabled"
      class="maplibre-like-button right-button record-button"
      :class="{ 'record-button-active': isRecordGeometryOpen }"
      size="small"
      color="light"
      @click="toggleRecordGeometryDialog"
    >
      <ion-icon
        slot="icon-only"
        :icon="radioButtonOnOutline"
      />
    </ion-button>
    <ion-button
      v-if="isPredefinedGeomsEnabled"
      class="maplibre-like-button predef-geometry-button right-button"
      size="small"
      color="light"
      @click="openGeometryModal(user?.organisation?.geometryConfig, map?.getCenter())"
    >
      <ion-icon
        slot="icon-only"
        :icon="shapesOutline"
      />
    </ion-button>
    <RecordGeometry
      v-if="isRecordGeometryEnabled && currentPosition && isRecordGeometryOpen && ba"
      :current-position="currentPosition"
      @recorded-geometry="handleRecordedGeometry"
      @delete-geometry="handleDeleteGeometry"
    />
    <div
      v-if="showConfirmAndCancelButtons"
      class="custom-action-buttons"
    >
      <ion-icon
        slot="icon-only"
        class="draw-handler confirm-cancel-handler draw-left-button cancel-draw-button"
        :icon="closeCircleOutline"
        @click="onCancelDrawing"
      />
      <ion-icon
        slot="icon-only"
        class="draw-handler confirm-cancel-handler draw-right-button confirm-draw-button"
        :class="{ disabled: confirmDrawButtonDisabled }"
        :icon="checkmarkCircleOutline"
        @click="onConfirmDrawing"
      />
    </div>
    <div
      v-else
      class="custom-action-buttons"
    >
      <div
        v-if="!!selectedFeature && userCanEditAnmerkung"
        class="circle-wrapper draw-handler draw-left-button edit-draw-button"
        @click="onStartEditing(selectedFeature)"
      >
        <ion-icon
          slot="icon-only"
          class="edit-handler"
          :icon="analyticsOutline"
        />
      </div>
      <div
        v-if="!!selectedFeature"
        class="circle-wrapper draw-handler draw-right-button edit-anmerkung-button"
        @click="emit('list:open-item', getRelatedFragenblock(selectedFeature))"
      >
        <ion-icon
          slot="icon-only"
          class="edit-handler"
          :icon="userCanEditAnmerkung ? createOutline : eyeOutline"
        />
      </div>
    </div>
    <slot />
  </div>
</template>

<script
    setup
    lang="ts"
>
import { IonButton, IonIcon } from "@ionic/vue";
import { analyticsOutline, checkmarkCircleOutline, closeCircleOutline, createOutline, eyeOutline, listOutline, radioButtonOnOutline, reloadOutline, scanOutline, shapesOutline } from 'ionicons/icons';
import { PropType, Ref, computed, onMounted, onUnmounted, ref, watch } from "vue";
//Interfaces & Enums
import { MapSettingsInterface } from "@/types/map/interfaces";
//Components
import FocusController from "@/components/hzba/Base/FocusController.vue";
//MapBox
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode";
import maplibregl, { GeolocateControl, Map, NavigationControl, Popup } from "maplibre-gl";
//Other
import GeoLayerToggle from "@/components/hzba/Base/GeoLayerToggle.vue";
import useBestandsaufnahmeUpload from "@/composables/Bestandsaufnahme/useBestandsaufnahmeUpload";
import useOnceDraw from "@/composables/Map/useDraw";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { Fragenblock } from "@/models/ba/Fragenblock";
import { createPopup } from "@/utilities/map-helper";
import { Monitoring } from "@/utilities/monitoring";
import { visibilityWatcher, watchElementDimensions } from "@/utilities/observer-utilities";
import { validateCenterSettingMonitored } from "@/utilities/validate-map-settings";
import * as tilebelt from '@mapbox/tilebelt';
import MaplibreGeocoder from '@maplibre/maplibre-gl-geocoder';
import '@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css';
import * as turf from "@turf/turf";
import PaintMode from "mapbox-gl-draw-paint-mode";
import RotateMode from 'mapbox-gl-draw-rotate-mode';
import { useI18n } from 'vue-i18n';
import RecordGeometry from "../Map/RecordGeometry.vue";
import { useIdentifierFragenblockFinder } from "@/composables/Bestandsaufnahme/useIdentifierFinder";

//Props
const props = defineProps({
    identifier: {
        type: String,
        required: true
    },
    mapSettings: {
        type: Object as PropType<MapSettingsInterface>,
        required: false,
        default: null
    },
    inputJson: {
        type: Object,
        default: undefined
    },
    isRecordGeometryEnabled: {
        type: Boolean,
        default: false
    },
    readonly: {
        type: Boolean,
        default: false
    },
    createPropertyMap: {
        type: Boolean,
        required: false
    },
    propertyLocation: {
        type: Object as PropType<{ lon: number, lat: number }>,
        required: false
    },
    ba: {
        type: Object as PropType<Bestandsaufnahme>,
        required: false,
    },
    routeName: {
        type: String,
        required: false
    },
    updateFeaturesCounter: {
        type: Number,
        default: 0,
        required: false
    },
    geoDataListActive: {
      type: Boolean,
      default: false,
      required: false
    }
});

//Emits
const emit = defineEmits([
    "map:loaded",
    "map:storeGeodata",
    "map:deleteGeodata",
    "map:zoomOverview",
    "update:inputJson",
    "update:mapSearch",
    "update:clearMapSearchInputs",
    "create:point",
    "point:click",
    "list:open",
    "list:close",
    "list:open-item",
    "drawing:created",
    "drawing:updated",
    "drag:start",
    "drag:end"
]);

const { updateSurvey } = useBestandsaufnahmeUpload()
//Variables
const { t } = useI18n({ useScope: 'global' })
const map = ref<Map>();
const mapContainer = ref();
const isMapSet = ref(false)
let draw: MapboxDraw;
let drawOptions: any;

const geodataInfo = ref("");
const isGeodataVisible = ref(false);
const selectedFeature = ref(null);
const selectedGeodataInfo = ref(null);
const existingFeaturesLoaded = ref(false);

let mapOptions: any;
const isLoading = ref(false);
const loadingProgress = ref(0);

const isGeolocateControlActive = ref(false);
const isTrackUserLocationActive = ref(false); // icon: blue circle with dot
const currentAccuracy = ref("");
const currentZoom = ref(0);
let db: any;

const store = useStore();
const isOnline = computed(() => {
    return store.getters["app/isOnline"];
});
const { user } = useUser();
const featureFlags = computed(() => user.value?.organisation?.featureFlags);
const isPredefinedGeomsEnabled = computed(() => props.identifier === "surveyMap" && featureFlags.value.predefinedGeometries && user?.value?.organisation?.geometryConfig);
const isRotateEnabled = ref(false);

const downloadedStyle: Ref<any> = ref(null);
let touchStartPosition = {
    x: null,
    y: null
};
let touchEndPosition = {
    x: null,
    y: null
};

const defaultButtonGroupHeight = ref(0);
const currentPosition: any = ref();
const defaultMapZoom = 17;
let popUp: Popup | null = null;
const geolocator: Ref<GeolocateControl | undefined > = ref();

const showGeolayerToggle = computed(() => {
  return !!props.mapSettings?.controller?.layerToggleMenu && !!isMapSet.value && !!downloadedStyle.value;
});

const showFocusController = computed(() => {
  return !!isMapSet.value && !!props.mapSettings?.controller?.focus;
});

const {
    setDrawInstance,
    onClickDraw,
    onDrawModeChange,
    onDrawCreate,
    showConfirmAndCancelButtons,
    confirmDrawButtonDisabled,
    onCancelDrawing,
    onConfirmDrawing,
    onSelectionChange,
    onStartEditing,
    setDrawCreateCallback,
    setDrawEditCallback,
    setSelectionChangeCallback,
    calculateOffsetAfterDraw,
    getExtendedDrawControl,
    collectControlElements,
    setControlVisibility,
    openGeometryModal,
    toggleRotateMode
} = useOnceDraw(props.identifier);

//Methods

// There is no exposed toggle API from maplibre, so using the internal method from here:
// https://github.com/maplibre/maplibre-gl-js/blob/87486a5ef2085e600e8fa4e31252629dd8488dcd/src/ui/control/attribution_control.ts#L107
const collapseAttributionControl = () => {
    const attributionElement = mapContainer.value.querySelector(".maplibregl-ctrl.maplibregl-ctrl-attrib");
    if (attributionElement) {
        attributionElement.removeAttribute('open');
        attributionElement.classList.remove('maplibregl-compact-show');
    }
}

function onClick(e: any) {
    if (onClickDraw(e)) {
        return;
    }
    emit('point:click', e);
    for (const feature of map.value.queryRenderedFeatures(e.point)) {
        if (feature.layer.source === "mapbox-gl-draw-cold") {
            return;
        }
    }

    if (popUp) {
        popUp.remove();
        popUp = null;
    }
    const propertyNamesToInclude = downloadedStyle.value?.on_click_info;

    if (propertyNamesToInclude) {
        const layerIDsToConsider = Object.keys(propertyNamesToInclude);
        const features = map.value.queryRenderedFeatures(e.point, {
            layers: layerIDsToConsider,
        });
        if (features.length > 0) {
            popUp = createPopup(features, propertyNamesToInclude, e.lngLat)
            if (popUp) {
                popUp.addTo(map.value);
            }
        }
    }
}

const getRelatedFragenblock = (feature: any) => {
    const parentFragenblock = props.ba?.findSectionByMapGeometryConfig() as Fragenblock
    return parentFragenblock.fragenblocks.find((item: any) => item.geoJson?.id === feature?.id) as Fragenblock
}

const currentUserOwnsFragenblock = (feature: any) => {
    const relatedFragenblock = getRelatedFragenblock(feature)
    return !!user.value?.id && relatedFragenblock?.config?.userId === user.value?.id
}

const userCanEditAnmerkung = computed(() => {
    return currentUserOwnsFragenblock(selectedFeature.value);
});

function preloadTiles() {
    // preload project bbox
    //if(navigator.onLine){
    if (isOnline.value) {
        isLoading.value = true;
        precacheRun(map.value, mapOptions);
    }
    // TODO: else show info message to user: sorry - you are offline
}

/**
 * Function to strip off the url of its protocol and search queries to use as a IndexedDB store keyPath
 * @param url
 */
function cleanUrl(url: any) {
    return url.includes("?") ? url.slice(url.indexOf("//") + 2, url.indexOf("?")) : url.slice(url.indexOf("//") + 2);
}

// Gets the needed information related to the Map object
const getContext = function (myMap: any, minZoom: any) {
    console.log("preload context: creating..");
    try {
        const _dimensions = [myMap.getCanvas().width, myMap.getCanvas().height];
        const _tilesize = myMap.transform.tileSize;
        const sc = myMap.getCenter();
        const zmin = Math.min(myMap.getZoom(), minZoom);

        // Only the tiled sources are needed
        const srces = Object.entries(myMap.getStyle().sources);
        const filtered = srces.filter(s => ['vector', 'raster'].indexOf(s[1].type) > -1 && (s[1].url !== undefined || s[1].tiles !== undefined));
        //const _sources = filtered.map(s => myMap.getSource(s[0]).tiles[0]);

        const mySources = [];
        for (let x = 0; x < filtered.length; x++) {
            const curr = filtered[x];
            const mySource = myMap.getSource(curr[0]);
            if (mySource.tiles) {
                const myTiles = mySource.tiles[0];
                mySources.push(myTiles);
            } else {
                console.log("preload context: no tiles for: " + mySource.id);
            }
        }
        // console.log("preload context: all sources: ", mySources);
        return {
            sources: mySources,
            dimensions: _dimensions,
            tilesize: _tilesize,
            startCenter: [sc.lng, sc.lat],
            startZoom: myMap.getZoom(),
            zmin: zmin,
            maxBounds: props.mapSettings?.maxBounds
        };
    } catch (e) {
        console.log("preload context error: ", e);
    }
};

// build and manage the preloader worker
const precacheRun = function (myMap: any, mapOptions: any) {

    const o = Object.assign({}, mapOptions, getContext(myMap, mapOptions.minZoom));
    try {
        console.log("preload run start ");
        /*if (window === self && myMap.precache_worker == undefined) {
            // the actual absolute path of the running script
            // as the module-typed workers are only supported by Chrome
            // we can get the path by throwing an error
            const _imported = ErrorStackParser.parse(new Error('not an actual error!'))[0].fileName;
            console.log("preload run error parsed ");
            // build inline worker
            const target = `
            importScripts('${_imported}');
            let controller;
            let signal;
            onmessage = function (o){
                if (controller !== undefined && controller.signal !== undefined && !controller.signal.aborted){
                    controller.abort();
                }
                if (o.data.abort){
                    postMessage({t: Date.now(), e: true});
                    return;
                }
                controller = new AbortController();
                signal = controller.signal;
                let _func = ${precache_function.toString()};
                console.log("preload run calling ");
                _func.apply(null, [o.data, signal]);
            }`;
            const mission = URL.createObjectURL(new Blob([target], { 'type': 'text/javascript' }));
            myMap.precache_worker = new Worker(mission);
            myMap.precache_worker.onmessage = (e:any) => {
                myMap.precache_worker.time1 = e.data.t;
                console.log(`Preload run time: ${myMap.precache_worker.time1 - myMap.precache_worker.time0}ms`);
            };
            console.log("preload run created ");
        }
        // Some debugging info
        delete myMap.precache_worker.time1;
        myMap.precache_worker.time0 = Date.now();
        //myMap.precache_worker.postMessage(o);
        console.log("preload run end ");
        */

        precache_function(o, null);
    } catch (e) {
        console.log("preload run error: ", e);
    }
};

const precache_function = (o: any, signal: any) => {

    // mapoptions:   container, style, center, minZoom, maxZoom, zoom
    // + context:    sources, dimensions, tilesize, startCenter, startZoom, zmin, maxBounds

    // Final scenario bbox
    //const finalbbox = bounds(o.center, o.zoom, o.dimensions, o.tilesize);
    // transition bbox

    // temporary use smaller bbox for Schwörstadt     TODO: reset to full bbox from mapconfig
    o.maxBounds = [
        [
            7.8398,
            47.6032
        ],
        [
            7.87,
            47.5847
        ]
    ];

    const transbbox = [
        Math.min(o.maxBounds[0][0], o.maxBounds[1][0]),
        Math.min(o.maxBounds[0][1], o.maxBounds[1][1]),
        Math.max(o.maxBounds[0][0], o.maxBounds[1][0]),
        Math.max(o.maxBounds[0][1], o.maxBounds[1][1]),
    ];

    // console.log("preload precache bbox: ", transbbox);

    // all the tiles in a bounding box for a given zoom level
    // including a buffer of 1 tile
    const bboxtiles = (bbox: any, zoom: any) => {
        const sw = tilebelt.pointToTile(bbox[0], bbox[1], zoom);
        const ne = tilebelt.pointToTile(bbox[2], bbox[3], zoom);
        const result = [];
        for (let x = sw[0] - 1; x < ne[0] + 2; x++) {
            for (let y = ne[1] - 1; y < sw[1] + 2; y++) {
                result.push([x, y, zoom]);
            }
        }
        return result;
    };

    let tz: number;
    let tiles = [];

    // Build the tiles pyramid for final scenario
    //for (let z = o.maxZoom; z >= o.minZoom; z--) { // mapconfig max/min zoom
    for (let z = 14; z >= 10; z--) { // temporary use less zoom-levels, TODO: check json for each source and use its minzoom / maxzoom (else many 404-errors for tiles!)
        try {
            const tt = bboxtiles(transbbox, z);
            tiles.push(...tt);
            tz = tt.length;
        } catch (e) {
            console.log("preload precache error collecting tiles: ", e);
        }
    }

    // console.log("preload precache tiles collected: ", tiles);
    // Get the tiles for the transition pan
    //tiles.push(...bboxtiles(transbbox, o.minZoom));

    // Remove duplicates
    tiles = [...new Set(tiles)];
    // From tiles [x,y,z] to URLs
    const urls = tiles.map(t => {
        return o.sources.map((s: any) => {
            return s.replace('{x}', t[0])
                .replace('{y}', t[1])
                .replace('{z}', t[2]);
        });
    }).flat();

    // console.log("preload precache prepared urls: ", urls);
    loadingProgress.value = 0;

    let cnt = 0;
    // Fetch all
    Promise.all(
        urls.map(
            u => {
                // TODO: check if tile already exists locally, decide if reload is required!
                fetch(u, { signal })
                    .then((response) => {
                        cnt++;
                        loadingProgress.value = (cnt / urls.length);
                        if (cnt % 100 === 0) {
                            // console.log("preload precache: downloaded tiles: " + cnt + " / " + urls.length);
                        }
                        if (cnt === urls.length) {
                            isLoading.value = false;
                        }
                        if (!response.ok) {
                            // console.log("preload response not ok: " + u, response);
                            return null;
                        }
                        return u.endsWith("json") ? response.json() : response.arrayBuffer();
                    })
                    .then((data) => {
                        if (data && db) {
                            // add: requires that no object already be in the database with the same key
                            // put: modify an existing entry, or you don't care if one exists already
                            db.transaction(["offline_map"], "readwrite").objectStore("offline_map").put({
                                url: cleanUrl(u),
                                fullurl: u,
                                type: u.endsWith("json") ? "json" : "arrayBuffer",
                                data,
                            });
                        }
                    })
                    .catch(function (error) {
                        cnt++;
                        loadingProgress.value = (cnt / urls.length);
                        if (cnt % 100 === 0) {
                            // console.log("preload precache: downloaded tiles: " + cnt + " / " + urls.length);
                        }
                        if (cnt === urls.length) {
                            isLoading.value = false;
                        }
                        // console.log('preload precache fetch error', error);
                    });
            }
        )
    )
        .then(d => {
            // console.log(`preload precache Estimated gain: ${Math.round(900 * tz / 6)}ms`);
            // console.log(`preload precache Prefetched ${urls.length} tiles at zoom levels [${o.minZoom} - ${o.maxZoom}]`);
            //postMessage({ t: Date.now(), e: false });
        })
        .catch(e => {
            console.log('🔴 Preload precache promise all error');
        });
};

async function fetchFullStyleObject() {
    try {
        Monitoring.setContext("MapStyle", { "StyleURL": mapOptions.style }, true);
        const resp = await fetch(mapOptions.style);

        if (!resp.ok) {
            Monitoring.error("Failed fetchFullStyleObject request.");
        }

        const styleJson = await resp.json();

        downloadedStyle.value = styleJson;
    } catch (error: any) {
        Monitoring.chainError("fetchFullStyleObject error", error);
    } finally {
        Monitoring.clearContexts();
    }
}

function showGeoDataListButton(features: any) {
    isGeodataVisible.value = features.length > 0;
}

function afterDrawCreated() {
    const drawnFeatures: any = draw.getAll();
    emit("update:inputJson", drawnFeatures);

    if (props.mapSettings?.minMax?.max && drawnFeatures.features.length >= props.mapSettings?.minMax?.max) {
        setControlVisibility("none");
    }
    showGeoDataListButton(drawnFeatures?.features);
}

function afterDrawDeleted() {
    const featuresAfterDelete: any = draw.getAll();

    emit("update:inputJson", featuresAfterDelete);

    if (
        props.mapSettings?.minMax?.max &&
        featuresAfterDelete.features.length <= props.mapSettings?.minMax?.max
    ) {
        setControlVisibility("block");
    }
    showGeoDataListButton(featuresAfterDelete?.features)

    //Since createPropertyMap can have just one point on the map, this is the trigger to clear input fields when point is removed
    if (props.createPropertyMap) {
        emit('update:clearMapSearchInputs')
    }
}

function afterDrawUpdate() {
    const drawnFeatures: any = draw.getAll();

    emit("update:inputJson", drawnFeatures);

    if (props.mapSettings?.minMax?.max && drawnFeatures.features.length >= props.mapSettings?.minMax?.max) {
        setControlVisibility("none");
    }
    showGeoDataListButton(drawnFeatures?.features);

    //Since createPropertyMap can have just one point on the map, this is the trigger to clear input fields when point is moved
    if (props.createPropertyMap) {
        emit('update:clearMapSearchInputs')
    }
}

async function handleDrawCreateAndUpdateSurvey(feature: any) {
    const drawItem = feature;
    const parentFragenblock = props.ba?.findSectionByMapGeometryConfig() as Fragenblock;
    const userId = user.value?.id;

    const fragenblock = await store.dispatch("fragenStore/createFragenblockFromFeature", { ba: props.ba, parentFragenblock, drawItem, userId });
    
    if (fragenblock) {
        afterDrawCreated();
        emit("drawing:created", fragenblock);
    }
}

async function handleDrawEditedAndUpdateSurvey( editedId:string) {
    const feature = draw.getAll().features.find((item: any) => item.id === editedId);
    const relatedFragenblock = getRelatedFragenblock(feature);

    relatedFragenblock.geoJson = feature;
    const updateFields = { geoJson: feature };
    store.dispatch("fragenStore/updateFragenblock", { ba: props.ba, fragenblock: relatedFragenblock, updateFields });
}

setDrawCreateCallback(handleDrawCreateAndUpdateSurvey);

setDrawEditCallback(handleDrawEditedAndUpdateSurvey);

function emitLoaded() {
    emit("map:loaded", map.value);
    displayPropertyLocation();
    displaySurveyMapData();

    const locateButton = document.getElementsByClassName('maplibregl-ctrl-geolocate');
    if (locateButton.length > 0) {
        locateButton[0].addEventListener("click", function () {
            if (!isGeolocateControlActive.value) {
                isGeolocateControlActive.value = true;
                console.log("Turn Find-my-location On");
            } else if (!isTrackUserLocationActive.value) {
                isGeolocateControlActive.value = false;
                currentAccuracy.value = "";
                console.log("Turn Find-my-location Off");
            }
        });
    }
}

function displayPropertyLocation() {
    if (props.propertyLocation) {
        const pointGeometry = {
            type: 'Point',
            coordinates: [Number(props.propertyLocation.lon), Number(props.propertyLocation.lat)]
        }

        const point = {
            type: 'Feature',
            geometry: pointGeometry,
            properties: {}
        } as any

        draw.add(point)
        const drawnFeatures: any = draw.getAll();
        showGeoDataListButton(drawnFeatures?.features);
    }
}

async function displaySurveyMapData() {
    if (props.identifier !== 'surveyMap') return;
    if (!props.ba) return;

    const parentFragenblock = props.ba?.findSectionByMapGeometryConfig() as Fragenblock
    const features = parentFragenblock?.fragenblocks.filter(item => item.geoJson) ?? [];

    draw.deleteAll();
    for (let i = 0; i < features.length; i++) {
        draw.add(features[i].geoJson)
    }
    existingFeaturesLoaded.value = true;
    const drawnFeatures: any = await draw.getAll();
    showGeoDataListButton(drawnFeatures?.features);
}


const handleSelection = (e: any) => {
  if (e.features && e.features.length > 0) {
    const feature = e.features[0];
    const featureId = feature.id;
    selectedFeature.value = feature;

    if (selectedGeodataInfo.value) {
      selectedGeodataInfo.value.classList.remove("bold-text");
    }

    selectedGeodataInfo.value = document.querySelector(
      `[data-feature-id="${featureId}"]`
    );

    if (selectedGeodataInfo.value) {
      selectedGeodataInfo.value.classList.add("bold-text");
    }
  } else {
    selectedFeature.value = null;
    if (selectedGeodataInfo.value) {
      selectedGeodataInfo.value.classList.remove("bold-text");
    }
  }
}

setSelectionChangeCallback(handleSelection);

function calcGeo(props: any) {
    const { type, coordinates } = props.geometry;
    let val = null;
    let unit = "";

    let lat, lng, latDirection, lngDirection; // Declare the variables outside the switch statement

    switch (type) {
        case "Point":
            lat = Number(coordinates[1]).toFixed(4) as any;
            lng = Number(coordinates[0]).toFixed(4) as any;
            latDirection = lat > 0 ? "N" : "S";
            lngDirection = lng > 0 ? "E" : "W";

            val = `${Math.abs(lat)} ${latDirection}, ${Math.abs(lng)} ${lngDirection}`;
            break;
        case "LineString":
        case "MultiLineString":
            val = Math.round(turf.length(props.geometry, { units: "meters" })).toLocaleString('de-DE');
            unit = "m";
            break;
        case "Polygon":
        case "MultiPolygon":
            val = Math.round(turf.area(props.geometry)).toLocaleString('de-DE');
            unit = "m²";
            break;
    }


    const obj = {
        area: val,
        unit: unit,
        label: val !== null ? val + " " + unit : null,
    };

    return obj;
}

const isRecordGeometryOpen = computed(() => store.state.app.isRecordGeometryOpen);

function toggleRecordGeometryDialog(): void {
    if ( !isRecordGeometryOpen.value && !isTrackUserLocationActive.value ) {
        geolocator.value?.trigger();
        isGeolocateControlActive.value = true;
    }
    onCancelDrawing();
    store.dispatch("app/setRecordGeometryOpen", !isRecordGeometryOpen.value);
}

async function handleRecordedGeometry( event: { isFinal: boolean, feature: any }) {
    if (draw.get( event.feature.id )) {
        draw.delete( event.feature.id );
    }

    draw.add(event.feature);

    if ( event.isFinal ) {
        await handleDrawCreateAndUpdateSurvey(event.feature);
        store.dispatch("app/setRecordGeometryOpen", false);
    }
}

function handleDeleteGeometry(id: string) {
   const deleteResult = draw.delete(id);
//    console.log("deleteResult", deleteResult);
}

const toggleList = () => {
    if(!props.geoDataListActive) {
        emit('list:open');
    } else {
        emit('list:close');
    }
}

watch(isRecordGeometryOpen, (newVal, oldVal) => {
    const geolocateButton: any = document.querySelector(".maplibregl-ctrl-geolocate");
    if ( geolocator.value && geolocateButton) {
        if ( newVal ) {
            geolocateButton.style.pointerEvents = "none";
        } else {
            geolocateButton.style.pointerEvents = "auto";
            isGeolocateControlActive.value = false;
        }
    }
})

watch(() => props.ba, (newVal) => {
    if (newVal && existingFeaturesLoaded.value === false) {
        displaySurveyMapData();
    }
});

// workaround, because changes in Fragenblock don't propagate to BA
watch(() => props.updateFeaturesCounter, () => {
    displaySurveyMapData();
    handleSelection({});
});

watch([mapContainer, defaultButtonGroupHeight, showFocusController, showGeolayerToggle], ([container, height, ...buttons]) => {
    let listButtonOffset = height + 5;
    if (!container) {
        return;
    }
    buttons.forEach((button) => {
        if (button) {
          listButtonOffset += 40;
        }
    });
    container?.style?.setProperty("--open-list-button-top-offset", `${listButtonOffset}px`);
});

//Lifecycle Hooks
onMounted(() => {
    const dbOpen = indexedDB.open("ms_store", 3);
    dbOpen.onupgradeneeded = (e) => {
        e.target.result.createObjectStore("offline_map", { keyPath: "url" });
    };

    dbOpen.onerror = (e: any) => {
        Monitoring.withScope((scope) => {
            scope.setContext("AppMap", { identifier: props.identifier });
            Monitoring.chainError("IndexedDB error in AppMap", e);
        });
    }

    dbOpen.onsuccess = (e) => {

        db = e.target.result;

        if (featureFlags.value?.offlineMap) {
            // Custom load resource function to save external resources into IndexedDB store
            maplibregl.addProtocol("ms_store_add", (params) => {
                params.url = params.url.replace("ms_store_add", "https");
                // console.log("ms_store_add: ", params);
                // TODO: check if tile already exists locally, decide if reload is required!
                return new Promise((resolve, reject) => {
                    fetch(params.url)
                        .then((response) => {
                            if (!response.ok) {
                                // console.log("ms_store_add response not ok: " + params.url, response);
                                return null;
                            }
                            return params.type === "json" ? response.json() : response.arrayBuffer();
                        })
                        .then((data) => {
                            if (data && db) {
                                // add: requires that no object already be in the database with the same key
                                // put: modify an existing entry, or you don't care if one exists already
                                db.transaction(["offline_map"], "readwrite").objectStore("offline_map").put({
                                    url: cleanUrl(params.url),
                                    fullurl: params.url,
                                    type: params.type,
                                    data,
                                });
                                params.data = data;
                                resolve(params); // promise was successful -> call resolve method from function params
                            }
                        })
                        .catch(function (error) {
                            console.log('ms_store_add then mserror', error);
                        });
                });
            });

            // Custom load resource function to fetch external resources from IndexedDB store instead
            maplibregl.addProtocol("ms_store_get", (params, abortController) => {
                params.url = params.url.replace("ms_store_get", "https");
                console.log("ms_store_get: ", params);
                return new Promise((resolve, reject) => {
                    const dbTransaction = db.transaction("offline_map").objectStore("offline_map").get(cleanUrl(params.url));
                    dbTransaction.onsuccess = (e: any) => {
                        //console.log("ms_store_get success: ", params, " result: ", e);
                        if (e.target.result) {
                            delete e.target.result.url;
                            resolve(e.target.result);
                        } else {
                            abortController.abort();
                        }
                    };
                    dbTransaction.onerror = (e: any) => {
                        // Handle errors!
                        console.log("ms_store_get error", e);
                    };
                });
            });
        }
    };

    mapOptions = {
        container: mapContainer.value,
        style: props.mapSettings?.style || props.mapSettings?.mapStyle?.style,
        center: props.identifier === "surveyMap" && props.propertyLocation ?
            [Number(props.propertyLocation.lon), Number(props.propertyLocation.lat)] :
            validateCenterSettingMonitored(props.mapSettings),
        minZoom: props.mapSettings?.minZoom || props.mapSettings?.mapStyle?.minZoom,
        maxZoom: props.mapSettings?.maxZoom || props.mapSettings?.mapStyle?.maxZoom,
        zoom: props.mapSettings?.mapInitialZoomLevel || props.mapSettings?.mapStyle?.mapInitialZoomLevel || defaultMapZoom
    };
    fetchFullStyleObject();

    // Change URL protocol to custom
    // alle externen requests abfangen und das protokoll ändern
    if (featureFlags.value?.offlineMap) {
        mapOptions.transformRequest = (url: any, resourceType: any) => {
            // do not change url protocol of first loading style-document
            if (url.includes("static.maptoolkit.net/styles/movinglayers/") || isOnline.value) {
                return { url: url };
            }
            return { url: url.replace(/^[a-z]+:\/\//, "ms_store_get://") };
            //return { url: url.replace(/^[a-z]+:\/\//, isOnline.value ? "ms_store_add://" : "ms_store_get://") };
            //return { url: url.replace(/^[a-z]+:\/\//, navigator.onLine ? "ms_store_add://" : "ms_store_get://") };
        };
    }

    // https://maplibre.org/maplibre-gl-js/docs/examples/mapbox-gl-draw/
    MapboxDraw.constants.classes.CONTROL_BASE = 'maplibregl-ctrl';
    MapboxDraw.constants.classes.CONTROL_PREFIX = 'maplibregl-ctrl-';
    MapboxDraw.constants.classes.CONTROL_GROUP = 'maplibregl-ctrl-group';

    // Drawbox overlay setup
    drawOptions = props.mapSettings?.drawOptions || {};
    drawOptions.modes = Object.assign({}, MapboxDraw.modes, { draw_paint_mode: PaintMode, static: StaticMode, RotateMode });
    isRotateEnabled.value = drawOptions?.controls?.rotate ?? false;
    const offset = calculateOffsetAfterDraw(drawOptions);
    if(offset) {
        let position = 0;

        //helper function to calculate the offset for the buttons
        // if AppMap is refactored to use addControl this could make this function obsolete
        // e.g. mapLibre.value.addControl(new CustomControl(topRightControl.value), Positions.TOP_RIGHT);
        const getOffset = () => {
            const newOffset = offset + position * 39;
            position += 1;
            return newOffset;
        }

        if(isRotateEnabled.value) {
            mapContainer.value.style.setProperty("--rotate-button-top-offset", `${getOffset()}px`);
        }
        if(props.isRecordGeometryEnabled) {
            mapContainer.value.style.setProperty("--record-button-top-offset", `${getOffset()}px`);
        }
        if(isPredefinedGeomsEnabled.value) {
            mapContainer.value.style.setProperty("--predef-geometry-top-offset", `${getOffset()}px`);
        }
    }
    try {
        map.value = new Map(mapOptions);
    } catch (error: any) {
        Monitoring.withScope((scope, scrub) => {
            scope.setContext("AppMap info", { identifier: props.identifier, style: scrub(mapOptions.style) });
            Monitoring.chainError("Error while initializing map", error);
        });
    }

    if(!map.value) {
        // TODO: show placeholder or reload button
        return;
    }
    draw = new MapboxDraw(drawOptions);
    setDrawInstance(map.value, draw, drawOptions);
    isMapSet.value = true;

    map.value.addControl(new NavigationControl({
        showCompass: props.mapSettings?.controller?.compass,
        showZoom: props.mapSettings?.controller?.zoom
    }), 'top-left');
    geolocator.value = new GeolocateControl({
        positionOptions: {
            enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showAccuracyCircle: true,
        fitBoundsOptions: {
            maxZoom: defaultMapZoom,
        },
    });
    map.value.addControl(geolocator.value, 'top-left');
    geolocator.value.on('trackuserlocationstart', () => {
        isTrackUserLocationActive.value = true;

    });
    geolocator.value.on('trackuserlocationend', () => {
        isTrackUserLocationActive.value = false;
    });
    geolocator.value.on('userlocationlostfocus', () => {
        const geolocateButton: any = document.querySelector(".maplibregl-ctrl-geolocate");
        if ( geolocateButton && geolocateButton.style.pointerEvents === "none") {
            geolocateButton.style.pointerEvents = "auto";
        }
    });
    geolocator.value.on('geolocate', (event) => {

        const { latitude, longitude, altitude } = event.coords;
        currentPosition.value = { lat: latitude, lon: longitude, alt: altitude };
        currentAccuracy.value = Number(Number(event.coords.accuracy).toFixed(2)).toLocaleString('de-DE'); // in meters

        const isActive = geolocator.value?._watchState === "ACTIVE_LOCK" ;
        if (isActive) {
            console.log("geolocate event - active");
            map.value?.jumpTo({
                center: [longitude, latitude],
                zoom: defaultMapZoom,
            });

            if (props.identifier === "surveyMap" && isRecordGeometryOpen.value) {
                const geolocateButton: any = document.querySelector(".maplibregl-ctrl-geolocate");
                geolocateButton.style.pointerEvents = "none";
            }

            // Call trigger to ensure the control remains active
            geolocator.value?.trigger();
            isGeolocateControlActive.value = true;
        }
    });

    if (featureFlags.value.geoCoder && props.createPropertyMap) {
        const geocoder = new MaplibreGeocoder({
            forwardGeocode: async (cfg: any) => {
                const response = await fetch(`https://geocoder.maptoolkit.net/search?q=${cfg.query}&language=${cfg.language[0]}&api_key=movinglayers`)
                const result = await response.json();
                return {
                    features: result.map((e: any) => ({
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [e.lon, e.lat]
                        },
                        place_type: ["place"],
                        place_name: e.display_name,
                        properties: e,
                        center: [e.lon, e.lat]
                    }))
                };
            },
        }, {
            showResultsWhileTyping: true,
            showResultMarkers: false,
            marker: false,
            maplibregl: maplibregl
        })
        map.value.addControl(geocoder, 'top-left')

        geocoder.on('result', function (e: any) {
            const localGeometry = {
                type: e.result.geometry.type,
                coordinates: [Number(e.result.geometry.coordinates[0]), Number(e.result.geometry.coordinates[1])]
            }
            const point = {
                type: 'Feature',
                geometry: localGeometry,
                properties: {}
            } as any

            draw.add(point)
            let drawnFeatures: any = draw.getAll();

            if (props.mapSettings?.minMax?.max) {
                if (drawnFeatures.features.length >= props.mapSettings?.minMax?.max) {
                    setControlVisibility("none");
                }

                if (drawnFeatures.features.length > props.mapSettings?.minMax?.max) {
                    draw.delete(drawnFeatures.features[0].id)
                    drawnFeatures = draw.getAll();
                }
            }
            showGeoDataListButton(drawnFeatures?.features);
            emit('update:mapSearch', e.result.properties)
        });
    }

    map.value?.on("error", (e: any) => {
        Monitoring.withScope((scope, scrub) => {
            scope.setContext("AppMap info", { "identifier": props.identifier, "style": scrub(mapOptions.style), "maplibre_version": map.value?.version });
            Monitoring.chainError("MapLibre error", e);
        });
    });
    map.value?.on("load", emitLoaded);
    map.value?.on("zoom", () => currentZoom.value = map.value.getZoom());
    map.value?.on("draw.create", (event: any) => props.identifier === "surveyMap" ? onDrawCreate() : afterDrawCreated());
    map.value?.on("draw.delete", () => afterDrawDeleted());
    map.value?.on("draw.update", () => afterDrawUpdate());
    map.value?.addControl(getExtendedDrawControl(), "top-right");
    map.value?.on("draw.selectionchange", onSelectionChange);
    if (props.mapSettings?.initialDrawnFeatures) {
        draw.add(props.mapSettings?.initialDrawnFeatures)
    }

    if (props.mapSettings?.maxBounds) {
        map.value?.setMaxBounds(props.mapSettings?.maxBounds);
    }

    map.value.once("load", () => {
        collectControlElements();
        collapseAttributionControl();
        //first time visible
        visibilityWatcher(mapContainer.value, () => {
          watchElementDimensions(mapContainer.value.querySelector(".maplibregl-ctrl-top-left"), (_width, height) => {
            defaultButtonGroupHeight.value = height;
          });
          collapseAttributionControl();
        }, true);
        // every time visible
        visibilityWatcher(mapContainer.value, () => {
            if (!isGeodataVisible.value) {
                // sometimes button was not shown after "load" event, use this as a fallback
                const drawnFeatures: any = draw.getAll();
                showGeoDataListButton(drawnFeatures?.features);
            }
        });

        props.inputJson && draw.add(props.inputJson);
        if (props.readonly) {
            draw.changeMode("static");
        }
        if (
            props.mapSettings?.minMax?.max &&
            draw.getAll().features.length >= props.mapSettings?.minMax?.max
        ) {
            setControlVisibility("none");
        }
        const drawnFeatures: any = draw.getAll();
        showGeoDataListButton(drawnFeatures?.features);
    });

    map.value?.on("draw.modechange", () => {
        onDrawModeChange();
    });

    map.value?.on("dragstart", () => {
       emit("drag:start");
    });

    map.value?.on("dragend", () => {
        emit("drag:end");
    });

    map.value?.on("click", (e: any) => {
        onClick(e)
    });

    map.value?.on('touchstart', (e: any) => {
        touchStartPosition = e.point;
    });

    map.value?.on('touchend', (e: any) => {
        touchEndPosition = e.point;
        if (touchStartPosition.x === touchEndPosition.x &&
            touchStartPosition.y === touchEndPosition.y) {
            onClick(e)
        }
    });
});

onUnmounted(() => {
    map.value?.remove();
});

</script>

<style scoped>
@import "https://unpkg.com/maplibre-gl@2.4.0/dist/maplibre-gl.css";
@import "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.3.0/mapbox-gl-draw.css";
</style>

<style
    scoped
    lang="scss"
>
.mapElement {

    .calculation-box p {
        margin: 1.25px;
    }

    .calculation-box {
        border-radius: 8px;
        z-index: 3;
        min-width: 85px;
        max-width: 200px;
        position: absolute;
        bottom: 10px;
        left: 10px;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 5px;
        text-align: center;
        display: none;
        max-height: 100px;
        overflow: auto;
        border: 1px solid var(--primary);
    }

    .accuracy-box p {
        margin: 1.25px;
    }

    .accuracy-box {
        border-radius: 8px;
        z-index: 99;
        min-width: 85px;
        max-width: 200px;
        position: absolute;
        top: 10px;
        left: 55px;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 5px;
        text-align: center;
        max-height: 100px;
        overflow: auto;
    }

    .visible {
        display: block;
    }

    .geodata-entry {
        cursor: pointer;
    }

    :deep(.mapbox-gl-draw_ctrl-draw-btn.active) {
        background-color: var(--secondary, rgba(0, 0, 0, 0.05));
    }

    // :deep(.maplibregl-canvas) {
    //    z-index: -1; /* necessary because safari displays canvas in the foreground instead of the background */
    // }
}

.zoomtoall-button {
    position: absolute;
    top: 142px;
    left: 8px;
    z-index: 4;
    width: 30px;
    color: var(--black100);
    background-color: var(--white100);
    border-radius: 4px;
    ;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
    --padding-bottom: 3px;
    --padding-top: 3px;
    --padding-start: 3px;
    --padding-end: 3px;
}

.maplibre-like-button {
  @include canvas-clipping;
  position: absolute;
  z-index: 4;
  width: 30px;
  color: var(--black100);
  background-color: var(--white100);
  border-radius: 4px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
  --padding-bottom: 3px;
  --padding-top: 3px;
  --padding-start: 3px;
  --padding-end: 3px;
  --background-activated: var(--secondary, rgba(0, 0, 0, 0.05));
}

.left-button {
  left: 8px;
}

.right-button {
  right: 8px;
}

.open-list-button {
  top: var(--open-list-button-top-offset, 181px);
  --background: var(--ion-color-light)
}

.record-button {
    top: var(--record-button-top-offset, 220px);
}

.record-button-active::part(native) {
    background: var(--secondary);
}

.predef-geometry-button {
    top: var(--predef-geometry-top-offset, 259px);
}

.rotate-button {
    top: var(--rotate-button-top-offset, 298px);
    --background: var(--ion-color-light)
}

ion-button.rotate-button.active,
ion-button.predef-geometry-button.active,
ion-button.open-list-button.active {
    --background: var(--secondary, rgba(0, 0, 0, 0.05));
}

/* purpose of circle-wrapper is to recreate ionic icon circleOutline style */
.circle-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;

  ion-icon {
    padding: 0.3rem;
  }

  &::after {
    content: "";
    position: absolute;
    --ring-padding: 0.3em;
    top: var(--ring-padding);
    left: var(--ring-padding);
    right: var(--ring-padding);
    bottom: var(--ring-padding);
    border: 1px solid currentColor;
    border-radius: 50%;
  }
}

.draw-handler {
  @include canvas-clipping;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  z-index: 10;
  opacity: 0.8;
  color: var(--ion-color-dark-tinte100);
  border-radius: 50%;
  --ionicon-stroke-width: 10px;
}

.confirm-cancel-handler {
  height: 2.5rem;
  width: 2.5rem;
}

.edit-handler {
  height: 1.5rem;
  width: 1.5rem;
}

.draw-handler.draw-left-button {
    right: 6.75rem;
}

.draw-handler.draw-right-button {
    right: 3.5rem;
}

.confirm-draw-button {
  background: var(--secondary);
  background: #DCF4DD;
}

.confirm-draw-button.disabled {
  opacity: 0.3;
}

.cancel-draw-button {
  background: #F2C9CC;
  background: #F4DDDE;
}

.edit-anmerkung-button {
  background: #FFEA17;
}

.edit-draw-button {
    background: #DDEBF4;
}

:deep(.maplibregl-ctrl-group) {
    @include canvas-clipping;
}

:deep(.maplibregl-ctrl.maplibregl-ctrl-attrib) {
    margin-bottom: var(--ion-safe-area-bottom, 0px);
}

</style>
<style lang="scss">

/* Custom styles for the GeolocateControl dot */
.custom-geolocate-style .maplibregl-user-location-dot {
    position: relative;             /* Required for positioning pseudo-elements */
    width: 40px;                    /* Circle size */
    height: 40px;
    border: 4px solid black;          /* Circle border */
    border-radius: 50%;             /* Make it a circle */
    background-color: transparent;  /* Transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

/* Completely override the default ::before and ::after pseudo-elements */
.custom-geolocate-style .maplibregl-user-location-dot::before,
.custom-geolocate-style .maplibregl-user-location-dot::after {
    /* Completely reset MapLibre's styles */
    all: unset;                     /* Reset all inherited styles */
    content: '';                    /* Ensure pseudo-elements are displayed */
    position: absolute;             /* Position them inside the circle */
    background-color: black;
    width: 40px;
    height: 1px;
}

/* Rotate to form the "X" */
.custom-geolocate-style .maplibregl-user-location-dot::before {
    transform: rotate(45deg);
}

.custom-geolocate-style .maplibregl-user-location-dot::after {
    transform: rotate(-45deg);
}


.custom-geolocate-style .maplibregl-user-location-dot::before,
.custom-geolocate-style .maplibregl-user-location-dot::after {
    z-index: 2;
}


</style>
