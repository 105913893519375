import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8ed1d316"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "boxed-container text-center mt-16"
}
const _hoisted_2 = {
  key: 1,
  class: "boxed-container text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isReadonly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ion_button, {
            fill: "clear",
            color: "danger",
            class: "d-button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.displayAlert()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.fragenblock.getTitle()) + " " + _toDisplayString(_ctx.t("hzba.buttons.loeschen")), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isReadonly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ion_button, {
            fill: "clear",
            color: "info",
            class: "d-button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('duplicate')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.fragenblock.getTitle()) + " " + _toDisplayString(_ctx.t("hzba.buttons.duplizieren")), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}