import { chainError } from "@/utilities/custom-error";
import { exponentialBackoff, ExponentialBackoffOptions } from "./api-helpers";

type AsyncFunc = () => Promise<any>;

type QueuedTask = {
    task: AsyncFunc;
    resolve: (value: any) => void;
    reject: (err: any) => void;
    retryOptions?: ExponentialBackoffOptions;
};

export const createAsyncQueue = (defaultRetryOptions?: ExponentialBackoffOptions) => {

    const queue: QueuedTask[] = [];
    let isProcessing = false;

    const processQueue = async () => {
        isProcessing = true;

        while (queue.length > 0 && isProcessing) {
            // @ts-ignore - compiler doesn't know that queue is not empty
            const { task, resolve, reject, retryOptions } = queue.shift(); // Get the next task in the queue

            try {
                const result = await exponentialBackoff(
                    task,
                    retryOptions ?? defaultRetryOptions ?? { retries: 3, initialDelay: 300 }
                );
                resolve(result);
            } catch (err: any) {
                reject(chainError(`Error while executing async task ${task.name} in queue`, err));
            }

        }
        isProcessing = false;
    };
    const enqueue = async (task: AsyncFunc, retryOptions?: ExponentialBackoffOptions) => {
        return new Promise((resolve, reject) => {
            queue.push({ task, resolve, reject, retryOptions });
            if (!isProcessing) {
                processQueue();
            }
        });
    };
    return { enqueue };
};

export type AsyncQueue = ReturnType<typeof createAsyncQueue>;
