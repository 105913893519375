<template>
  <div>
    <!--
    *
    * First layer: Fragenblocks and 1..N Fragenblocks
    *
    -->
    <div
      v-for="(fragenblock, index) in fragenblocks"
      :key="fragenblock.uid ? fragenblock.uid : `outer-${index}`"
    >
      <hzba-group
        v-if="!fragenblock.config?.isTemplate && fragenblock.isShown()"
        :title="fragenblock.getTitle()"
        :suffix="fragenblock.getIndexPosition(parentFragenblock)"
        hide-progress
        :data-cy="`group-`+fragenblock.identifier"
        class="relative"
        :foldable="fragenblock.config?.foldable"
      >
        <div
          v-if="enabledDevMode"
          class="absolute top-0 right-2 z-10"
          @click="activeDebug = activeDebug === index ? undefined : index"
        >
          o
        </div>
        <div
          v-if="featureFlags?.survey?.copyFragenblock"
          class="absolute top-4 right-4 h-8 text-base cursor-pointer"
          @click="openSourceListForCopy(fragenblock)"
        >
          <ion-icon
            slot="icon-only"
            :icon="copy"
          />
        </div>
        <DebugFragenblock
          v-if="enabledDevMode && activeDebug === index"
          :fragenblock="fragenblock"
          @close="activeDebug = undefined"
        />
        <template
          v-if="fragenblock.isInstanceOfMultiple()"
          #cta
        >
          <hzba-group-ctas
            :hide-add-button="true"
            @delete="deleteGroupCta(fragenblock)"
            @duplicate="duplicateGroupCta(fragenblock)"
          />
        </template>

        <!--
        *
        * Second layer: Here we find questions and (1..n) fragenblocks that directly opens a modal
        *
        -->

        <!-- Questions -->

        <!-- (1..N) Fragenblocks -->
        <div
          v-for="(fragenB, i) in fragenblock.fragenblocks"
          :key="`${i}`"
          class="relative"
        >
          <!-- simple components - click opens modal -->
          <hzba-modal-item-block
            v-if="!fragenB.config?.isTemplate && !fragenB.isInstanceOfMultiple() && fragenB.isShown()"
            :data-cy="`modal-${fragenB.identifier}`"
            :title="fragenB.getTitle()"
            :progress="fragenB.getProgress()"
            :disabled="!fragenB.isUnlocked() || isUpdating"
            :maengel-amount="fragenB.getFiredMaengel().length"
            :errors="fragenB.errors()"
            @click-item="
              () => openHzbaModal(
                'fragenblock',
                { ba, fragenblockBase: fragenB, modalDepth: modalDepth+1 },
                (data) => setDataFromCallback(fragenblock, index, i, data),
                { saveLocally: false }
              )
            "
          />

          <!-- 1:N components - click opens modal -->
          <div v-else-if="fragenB.config?.isTemplate">
            <hzba-separator>
              {{ fragenB.getTitle() }}
              <span v-if="fragenB.config?.minCount || fragenB.config?.maxCount">(</span>
              <span v-if="fragenB.config?.minCount">min. {{ fragenB.config.minCount }}</span>
              <span v-if="fragenB.config?.minCount && fragenB.config?.maxCount">,&nbsp;</span>
              <span v-if="fragenB.config?.minCount">max. {{ fragenB.config.maxCount }}</span>
              <span v-if="fragenB.config?.minCount || fragenB.config?.maxCount">)</span>
            </hzba-separator>

            <div>
              <hzba-modal-item-block
                v-for="(multiFragenBlock, multiIndex) in fragenblock.getMultipleFragenblockInstances(fragenB.identifier)"
                :key="multiFragenBlock.uid"
                :data-cy="`modal-${multiFragenBlock.identifier}-${multiIndex}`"
                :title="multiFragenBlock.getTitle()"
                :progress="multiFragenBlock.getProgress()"
                :suffix="`${(multiIndex + 1)}`"
                :disabled="!multiFragenBlock.isUnlocked() || isUpdating"
                :maengel-amount="multiFragenBlock.getFiredMaengel().length"
                :errors="multiFragenBlock.errors()"
                slide-enabled
                @click-item="
                  () => openHzbaModal(
                    'fragenblock',
                    { ba, fragenblockBase: multiFragenBlock, modalDepth: modalDepth + 1 },
                    (data) => setDataFromCallback(fragenblock, index, i, data),
                    { saveLocally: false }
                  )
                "
                @delete="deleteGroupCta(multiFragenBlock)"
                @duplicate="duplicateGroupCta(multiFragenBlock)"
              />
            </div>

            <hzba-modal-item-block
              v-if="!isReadonly"
              :title="fragenB.getTitle() + ' ' + t('hzba.buttons.hinzufuegen')"
              :data-cy="`modal-${fragenB.identifier}-buttons`"
              add-icon
              :disabled="isUpdating"
              @click-item="addFragenblockInstance(fragenblock, fragenB)"
            />
          </div>

          <div
            v-if="enabledDevMode"
            class="absolute top-0 right-2 z-10"
            @click="activeDebug = activeDebug === `sub-${i}` ? undefined : `sub-${i}`"
          >
            o
          </div>
          <DebugFragenblock
            v-if="enabledDevMode && activeDebug === `sub-${i}`"
            :fragenblock="fragenB"
            @close="activeDebug = undefined"
          />
        </div>

        <hzba-form
          :form-frages="fragenblock.frages"
          @input-changed="handleFormInputChange"
        />

        <AdditionalMaengel
          v-if="fragenblock.config?.customMaengel?.enabled"
          :path="fragenblock.path"
          :mangels="fragenblock.freieMangels"
          :config="fragenblock.config?.customMaengel"
          :fragenblock="fragenblock"
        />
      </hzba-group>

    <!--    <pre>{{ parentFragenblock.toClassJson() }}</pre>-->
    </div>

    <div
      v-if="!isReadonly"
    >
      <div
        v-for="(fragenblock, index) in fragenblocks"
        :key="index"
      >
        <div
          v-if="fragenblock.config?.isTemplate"
          class="boxed-container p-4 text-center"
        >
          <IonButton
            fill="clear"
            data-cy="button-neuer-waermeerzeuger"
            class="normal-case"
            @click="addFragenblockInstance($props.parentFragenblock, fragenblock)"
          >
            {{ fragenblock.getTitle() + " " + t("hzba.buttons.hinzufuegen").toLowerCase() }}
          </IonButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { putBestandsaufnahme } from "@/api/update-survey-api";
import AdditionalMaengel from "@/components/hzba/Base/AdditionalMaengel.vue";
import HzbaForm from "@/components/hzba/Base/HzbaForm.vue";
import HzbaGroupCtas from "@/components/hzba/Base/HzbaGroupCtas.vue";
import DebugFragenblock from "@/components/Other/DebugFragenblock.vue";
import useBestandsaufnahmeSockets from "@/composables/Bestandsaufnahme/useBestandsaufnahmeSockets";
import useBestandsaufnahmeUpload from "@/composables/Bestandsaufnahme/useBestandsaufnahmeUpload";
import { getFragenblockWithIndices } from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { Frage } from "@/models/ba/Frage";
import { Fragenblock } from "@/models/ba/Fragenblock";
import { HzbaStatusCode } from "@/models/ba/interfaces/IBestandsaufnahme";
import { FragenblockJson } from "@/models/ba/interfaces/IFragenblock";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import { inputFieldKeys } from "@/utilities/constants";
import { openHzbaModal } from "@/utilities/modal-helper";
import { Monitoring } from "@/utilities/monitoring";
import { Network } from "@capacitor/network";
import { alertController, IonButton, IonIcon } from "@ionic/vue";
import { copy } from "ionicons/icons";
import _ from 'lodash';
import { computed, defineComponent, PropType, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import HzbaGroup from "./Base/HzbaGroup";
import HzbaModalItem from "./Base/Input/HzbaModalItem";
import HzbaModalItemBlock from "./Base/Input/HzbaModalItemBlock";
import HzbaSeparator from "./Base/Input/HzbaSeparator";

export default defineComponent({
  name: "RecursiveForm",
  components: {
    DebugFragenblock,
    AdditionalMaengel,
    HzbaGroupCtas, HzbaForm, HzbaSeparator, HzbaModalItemBlock, HzbaModalItem, HzbaGroup, IonButton, IonIcon },
  props: {
    parentFragenblock: {
      type: Object as PropType<Fragenblock>,
      required: true
    },
    modalDepth: {
      type: Number,
      default: -1,
    },
    saveOnFrageInputChanged: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })
    const store = useStore()
    const { user } = useUser();
    const activeDebug = ref();
    const ba = computed<Bestandsaufnahme | undefined>(() => {
      return store.state.currentHzba.currentBa;
    });
    const fragenblocks = computed(() => {
      return props.parentFragenblock && props.parentFragenblock.fragenblocks
    });
    const currentProject = computed(() => store.state.user.currentUserProject);
    const featureFlags = computed(() => user.value?.organisation?.featureFlags);
    const { updateSurvey, isUpdating } = useBestandsaufnahmeUpload()
    const socketBa = useBestandsaufnahmeSockets();

    watch(() => props.parentFragenblock, async (newVal) => {
      props.parentFragenblock?.fragenblocks?.forEach(el => el.setupUnlockDetector())
      props.parentFragenblock?.fragenblocks?.forEach(el => el.setupShowDetector())
    }, { immediate: true })

    const setDataFromCallback = async (parentFragenblock: Fragenblock, index: number, i: number, callbackData: { action: string; data: FragenblockJson }) => {
      const parentPath = parentFragenblock.path;
      const parentPathWithMultiFragenblockIndex = parentFragenblock.pathWithMultiFragenblockIndex;
      const fragenblock = new Fragenblock(callbackData.data, parentPath, props.modalDepth, parentPathWithMultiFragenblockIndex);

      /*  - Workaround needed because ba.value and parentFragenblock have no reactive connection
          - When updating the parentFragenblock (through the store actions by adding or duplicating new children) 
              from the function parameters, ba.value and props.fragenblock are not updated
          - When getting right parentFragenblock for this nesting-level from props.parentFragenblock, ba.value is not updated
          - Solution: Create mutableBa, derive parentFragenblock from it and call store.storeMoverSurvey at the end to update currentBa  */
      const baJson = await ba.value?.toClassJson();
      let mutableBa;
      if (baJson) {
        mutableBa = new Bestandsaufnahme(baJson, undefined);
      } else {
        mutableBa = ba.value; // should not happen, but adding it for typescript, update cycle would not work in this case
      }
      const parent = mutableBa?.reassociateFragenblock(parentPath) ?? {};

      if (callbackData.action === 'DUPLICATE') {
         await store.dispatch("fragenStore/duplicateFragenblock", { ba: mutableBa, parentFragenblock: parent, fragenblock });
      } else if (callbackData.action === 'DELETE') {
        await store.dispatch("fragenStore/disconnectAndDeleteFragenblock", { ba: mutableBa, parentFragenblock: parent, fragenblock });
      }
      const updatedBa = await mutableBa?.toClassJson();
      await BestandsaufnahmeModel.dispatch("storeMoverSurvey", updatedBa);
      await store.dispatch("currentHzba/updateCurrentBa", updatedBa);

      /**
       * After a modal was saved, we need to refresh the show/unlockdetectors because they might reference to an outdated (and removed) object now.
       */
      console.log("Recursive form after save...");
      fragenblocks.value?.forEach(el => el.setupUnlockDetector())
      fragenblocks.value?.forEach(el => el.setupShowDetector())
    }

    const addFragenblockInstance = async (parentFragenblock: Fragenblock, fragenblock: Fragenblock) => {
      store.dispatch("fragenStore/addFragenblock", { ba: ba.value, parentFragenblock, fragenblock });
    }

    const deleteGroupCta = async (fragenblock: Fragenblock) => {
      store.dispatch("fragenStore/disconnectAndDeleteFragenblock", { ba: ba.value, parentFragenblock: props.parentFragenblock, fragenblock });
    }

    const duplicateGroupCta = async (fragenblock: Fragenblock) => {
      store.dispatch("fragenStore/duplicateFragenblock", { ba: ba.value, parentFragenblock: props.parentFragenblock, fragenblock });
    }

    const saveLocally = _.debounce(async () => {
      const oldMalus = ba.value?.malus;
      const oldMalusColor = ba.value?.malusColor;

      await store.dispatch("currentHzba/saveHzbaLocally");
      setBestandsaufnahmeStatusInProgress();
      // Compare old and new values after the dispatch
      const newMalus = ba.value?.malus;
      const newMalusColor = ba.value?.malusColor;
      const networkStatus = await Network.getStatus();

      if ( networkStatus.connected && ( oldMalusColor !== newMalusColor || oldMalus !== newMalus ) ) {
        console.log("malusColor has changed from", oldMalusColor, "to", newMalusColor);
        const copiedBa = await ba.value?.copyLeanJson({prepareForSync: true});
        // console.log('Copied BA JSON before uploading:', {data: copiedBa});

        const baUploadRes = await BestandsaufnahmeModel.api().put(`/bestandsaufnahmes/${copiedBa?.id}?projectId=${currentProject.value.id}`,
            {data: copiedBa},
            {save: false},
        );
      }
    }, 500)

    const setBestandsaufnahmeStatusInProgress = async () => {
      try {
        if(ba.value?.status === HzbaStatusCode.ANGELEGT || ba.value?.status === HzbaStatusCode.GEPLANT) {
          const isOnline = store.getters["app/isOnline"];
          let updatedBaJson = null;

          if(isOnline) {
            const fieldsToSet: any = {
              status: HzbaStatusCode.IN_DURCHFUEHRUNG
            };
            if(featureFlags?.value.survey?.showSichtungsdatum && !ba.value.sichtungsdatum) {
              fieldsToSet.sichtungsdatum = new Date().toISOString();
            }
            const oldSurvey = await ba.value.toClassJson();
            updatedBaJson = await putBestandsaufnahme(ba.value.id, currentProject.value.id, fieldsToSet, oldSurvey);
          } else {
            updatedBaJson = await ba.value.toClassJson()
            updatedBaJson.status = HzbaStatusCode.IN_DURCHFUEHRUNG;
            if(featureFlags?.value.survey?.showSichtungsdatum && !ba.value.sichtungsdatum) {
              updatedBaJson.sichtungsdatum = new Date().toISOString();
            }
          }

          await BestandsaufnahmeModel.insertOrUpdate({ data: updatedBaJson });
          const updatedBa = new Bestandsaufnahme(updatedBaJson, undefined);
          updatedBa.isLocal = false; // legacy property
          store.commit('currentHzba/setCurrentBa', updatedBa);
        }
      } catch (error: any) {
        Monitoring.chainError("Error while setting status to IN_DURCHFUEHRUNG", error);
      }
    }

    const handleFormInputChange = () => {
        console.log("handleFormInputChange");
        saveLocally();
    }

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    const enabledDevMode = computed(() => store.state.app.enabledDevMode );

    const copyFragenblockData = (targetFragenblock: Fragenblock) => async (sourceSurveyId: number) => {
        try {
            const sourceBa: any = BestandsaufnahmeModel.query().find(sourceSurveyId);
            const sourceBaClass = new Bestandsaufnahme(sourceBa)
            const sourceFragenblock = getFragenblockWithIndices(sourceBaClass, targetFragenblock.pathWithMultiFragenblockIndex);

            sourceFragenblock?.frages?.forEach((sourceFrage: Frage, index: number) => {
                if (!targetFragenblock?.frages || !targetFragenblock?.frages[index]) {
                    return;
                }

                Object.keys(sourceFrage).forEach((key: string) => {
                    if (inputFieldKeys.includes(key) && targetFragenblock.frages && targetFragenblock.frages[index]) {
                        if ( !targetFragenblock.frages[index].config.readOnly) {
                            // @ts-ignore
                            targetFragenblock.frages[index][key] = sourceFrage[key];
                        }
                    }
                });
            });
            socketBa.handleSocketEmit( targetFragenblock.frages as Frage[]);
        } catch (error: any) {
            Monitoring.chainError("Error while copying Fragenblock", error);
        }
    }

    const openSourceListForCopy = async ( fragenblock: Fragenblock) => {
        try {
            const alertButtons = [
                {
                    text:  t("hzba.buttons.select"),
                    handler: copyFragenblockData(fragenblock )
                },
                t('hzba.buttons.cancel')
            ];

            const moverSurveys: any[] = BestandsaufnahmeModel.getters("moverSurveys")
                .filter((survey: BestandsaufnahmeModel) => survey.identifier === ba.value?.identifier && survey.id !== ba.value?.id)
                .map((item: any) => ({
                label: item.id + ", " + item.identifier,
                type: 'radio',
                value: item.id
            }));

            const alert = await alertController.create({
                header: t("hzba.buttons.copyFragenblockTitle"),
                buttons: alertButtons,
                inputs: moverSurveys
            });
            alert.present();
        } catch (error: any) {
            Monitoring.chainError("Error while opening Fragenblock Copy alert", error);
        }
    }
    return {
      fragenblocks,
      setDataFromCallback,
      addFragenblockInstance,
      deleteGroupCta,
      duplicateGroupCta,
      t,
      handleFormInputChange,
      isReadonly,
      openHzbaModal,
      activeDebug,
      enabledDevMode,
      updateSurvey,
      isUpdating,
      copy,
      openSourceListForCopy,
      featureFlags
    }
  },
})
</script>

<style scoped>

</style>