import { FragenblockJson } from "@/models/ba/interfaces/IFragenblock";
import { AxiosRequestConfig } from "axios";
import apiClient, { strapiApiUrl } from "./api";

interface PostInput { newFragenblock: FragenblockJson, parentFragenblockId: number; }
interface PutInput { fragenblockFields: Record<string, any>; }
interface DeleteInput { id: number; parentId: number; }

export const postFragenblock = (data: PostInput, config: AxiosRequestConfig = {}) => {
    return apiClient.post(`${strapiApiUrl}/fragenblocks`, {
        data
    }, config);
};

export const putFragenblock = (data: PutInput, config: AxiosRequestConfig = {}) => {
    return apiClient.put(`${strapiApiUrl}/fragenblocks/${data.fragenblockFields.id}`, {
        data
    }, config);
};

export const disconnectAndDeleteFragenblockRecursive = (data: DeleteInput, config: AxiosRequestConfig = {}) => {
    return apiClient.put(`${strapiApiUrl}/fragenblocks/disconnect/${data.id}`, {
        data: {
            parentFragenblockId: data.parentId
        }
    }, config);
};